import {useState} from "react"
import {Button} from "../../public-components/Button/Button"


export const TextPage = () => {

  const [textAlign, setTextAlign] = useState('left');
  const [fontWeight, setFontWeight] = useState('400');
  const [color, setColor] = useState('black');
  const [spacing, setSpacing] = useState('0');
  const [size, setSize] = useState('72px');
  const [style, setStyle] = useState('italic');
  const [font, setFont] = useState('sans-serif');
  const [decoration, setDecoration] = useState('none');

  const styles = {
    textAlign: textAlign,
    fontWeight: fontWeight,
    color: color,
    letterSpacing: spacing,
    fontSize: size,
    fontStyle: style,
    fontFamily: font,
    textDecoration: decoration,
  }

  return(<div className="css-page text-page">
    <h2>Text</h2>

    <p className="description">
      Text styles in CSS control how text appears on a webpage. Here's a breakdown:<br /><br />
      <ul>
        <li><b>text-align:</b>Sets the horizontal alignment of text. Options: left, center, right.</li>
        <li><b>font-weight:</b>The thickness of characters. Common values are 100 (thin) to 700 (bold).</li>
        <li><b>color:</b>Defines text color. Can use names (like 'black'), hex codes ('#FFFF00'), or RGBA values.</li>
        <li><b>letter-spacing:</b>Space between characters. Measured in pixels (px).</li>
        <li><b>font-size:</b>The size of the text. Larger values mean bigger text.</li>
        <li><b>font-style:</b>The style of the font, like 'italic' or 'normal'.</li>
        <li><b>font-family:</b>The font used, e.g., 'sans-serif', 'serif', or 'monospace'.</li>
        <li><b>text-decoration:</b>Adds decoration to text, such as 'underline', 'line-through', or 'none'.</li>
      </ul>
    </p>

    <div className="playground">
      <p style={styles}>Here is my sample text</p>
    </div>

    <div className="playground-options flex-column">

      <div className="flex-row gap-16">
        <h3>text-align:</h3>
        <Button className={textAlign === 'left' ? 'active' : ''} onClick={() => setTextAlign('left')}>left</Button>
        <Button className={textAlign === 'center' ? 'active' : ''} onClick={() => setTextAlign('center')}>center</Button>
        <Button className={textAlign === 'right' ? 'active' : ''} onClick={() => setTextAlign('right')}>right</Button>
      </div>
      <div className="flex-row gap-16">
        <h3>font-weight:</h3>
        <Button className={fontWeight === '100' ? 'active' : ''} onClick={() => setFontWeight('100')}>100</Button>
        <Button className={fontWeight === '400' ? 'active' : ''} onClick={() => setFontWeight('400')}>400</Button>
        <Button className={fontWeight === '700' ? 'active' : ''} onClick={() => setFontWeight('700')}>700</Button>
      </div>
      <div className="flex-row gap-16">
        <h3>color:</h3>
        <Button className={color === 'blue' ? 'active' : ''} onClick={() => setColor('blue')}>blue</Button>
        <Button className={color === 'red' ? 'active' : ''} onClick={() => setColor('red')}>red</Button>
        <Button className={color === 'rgba(0,0,255,0.5)' ? 'active' : ''} onClick={() => setColor('rgba(0,0,255,0.5)')}>rgba(0,0,255,0.5)</Button>
        <Button className={color === 'black' ? 'active' : ''} onClick={() => setColor('black')}>black</Button>
        <Button className={color === 'transparent' ? 'active' : ''} onClick={() => setColor('transparent')}>transparent</Button>
        <Button className={color === '#FFFF00' ? 'active' : ''} onClick={() => setColor('#FFFF00')}>#FFFF00</Button>
      </div>
      <div className="flex-row gap-16">
        <h3>letter-spacing:</h3>
        <Button className={spacing === '0' ? 'active' : ''} onClick={() => setSpacing('0')}>0</Button>
        <Button className={spacing === '1px' ? 'active' : ''} onClick={() => setSpacing('1px')}>1px</Button>
        <Button className={spacing === '3px' ? 'active' : ''} onClick={() => setSpacing('3px')}>3px</Button>
        <Button className={spacing === '10px' ? 'active' : ''} onClick={() => setSpacing('10px')}>10px</Button>
      </div>
      <div className="flex-row gap-16">
        <h3>font-size:</h3>
        <Button className={size === '18px' ? 'active' : ''} onClick={() => setSize('18px')}>18px</Button>
        <Button className={size === '24px' ? 'active' : ''} onClick={() => setSize('24px')}>24px</Button>
        <Button className={size === '48px' ? 'active' : ''} onClick={() => setSize('48px')}>48px</Button>
        <Button className={size === '72px' ? 'active' : ''} onClick={() => setSize('72px')}>72px</Button>
      </div>
      <div className="flex-row gap-16">
        <h3>font-style:</h3>
        <Button className={style === 'italic' ? 'active' : ''} onClick={() => setStyle('italic')}>italic</Button>
        <Button className={style === 'normal' ? 'active' : ''} onClick={() => setStyle('normal')}>normal</Button>
      </div>
      <div className="flex-row gap-16">
        <h3>font-family:</h3>
        <Button className={font === 'serif' ? 'active' : ''} onClick={() => setFont('serif')}>serif</Button>
        <Button className={font === 'sans-serif' ? 'active' : ''} onClick={() => setFont('sans-serif')}>sans-serif</Button>
        <Button className={font === 'monospace' ? 'active' : ''} onClick={() => setFont('monospace')}>monospace</Button>
      </div>
      <div className="flex-row gap-16">
        <h3>text-decoration:</h3>
        <Button className={decoration === 'none' ? 'active' : ''} onClick={() => setDecoration('none')}>none</Button>
        <Button className={decoration === 'line-through' ? 'active' : ''} onClick={() => setDecoration('line-through')}>line-through</Button>
        <Button className={decoration === 'underline' ? 'active' : ''} onClick={() => setDecoration('underline')}>underline</Button>
        <Button className={decoration === 'overline' ? 'active' : ''} onClick={() => setDecoration('overline')}>overline</Button>
      </div>

    </div>
  </div>)
}