import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const InputCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
        
      </div>
    </>
  )
}



const componentReact = 
`import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = ({}) => {

  return (
    <Input
      label="Text Element"
      column={3}
      width="300px"
      type="text"
      onChange={() => {}}
    />
  )
}
/** This is a simple example of how the component works **/


export const Input = ({
  label,
  column,
  width = '100%',
  onChange,
  value = '',
  type,
  required = false,
  showErrorMsg = false,
  id,
}) => {

  const [localValue, setLocalValue] = useState(value);
  const [error, setError] = useState('');

  useEffect(() => {
    onChange(localValue);
  }, [localValue, onChange]);

  const handleChange = (e) => {

    let filteredValue = e.target.value;

    // Input is empty, remove error state
    if(filteredValue === '') {
      setError('');
      setLocalValue(filteredValue);
      return;
    }

    switch(type) {

      case 'tel':

        // Format telephone number: +12 (888) 345-6789
        filteredValue = filteredValue.replace(/\D/g, '');

        if(filteredValue.length > 12) return;

        if (filteredValue.length > 1) {
          let cc = 0;
          if(filteredValue.length === 12) cc = 1;
          let formattedNumber = \`+\${filteredValue.substring(0, 1+cc)} (\${filteredValue.substring(1+cc, 4+cc)}) \${filteredValue.substring(4+cc,7+cc)}\`;
          if(filteredValue.length > 7) formattedNumber += \`-\${filteredValue.substring(7+cc)}\`;
          filteredValue = formattedNumber;
        } 
        break;

      case 'url':

        // Validate URL
        const pattern = new RegExp('\\b((?:[a-z][\\w-]+:(?:/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s\`!()\\[\\]{};:\'".,<>?«»“”‘’]))','i');
        const hasInvalidFirstChar = !/^[a-z]/i.test(filteredValue);
        const hasInvalidLastChar = filteredValue.slice(-1) === '.'
        const hasLessThanTwoDots = filteredValue.split('.').length - 1 < 2;
        const hasInvalidRegex = !pattern.test(filteredValue);

        if(hasInvalidFirstChar || hasInvalidLastChar || hasLessThanTwoDots || hasInvalidRegex) {
          setError('Error: Invalid URL');
        } else {
          setError('');
        }
        break;
    }

    setLocalValue(filteredValue);
  };

  const labelStyles = {
    width: column ? \`calc((100% / 12) * \${column})\` : width,
    ...(error && {color: 'red'}),
  }
  
  const inputStyles = {
    ...(error && {borderColor: 'red'}),
  }

  const inputId = id || 'input-' + Math.floor(Math.random() * 999999);
  const errorId = \`\${inputId}-error\`;

  return (
    <label htmlFor={inputId}  className="cc-input" style={labelStyles}>
      <span className="cc-input-label">{label}</span>
      <input
        type={!['url','email'].includes(type) ? type : null}
        className="cc-input-element"
        value={localValue}
        onChange={handleChange}
        style={inputStyles}
        aria-required={required}
        aria-invalid={!!error}
        aria-describedby={showErrorMsg ? error : null}
        id={id || inputId}
      />
      {showErrorMsg &&
        <span
          id={errorId}
          className="cc-input-error"
          role="alert"
        >
          {error}
        </span>
      }
    </label>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  column: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  width: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'color',
    'date', 
    'email',
    'file',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'time',
    'url'
  ]),
}`;

const componentHtml =
``;

const componentCss =
`/* Input */
.cc-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cc-input-label {
  font-size: 14px;
  line-height: 12px;
}

.cc-input-element {
  height: 32px;
  width: 100%;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid black;
  box-sizing: border-box;
}

.cc-input-error {
  font-size: 12px;
  line-height: 10px;
  height: 10px;
}

.cc-input-error.hidden {
  opacity: 0;
}`;

const componentJs =
``;
