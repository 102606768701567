import {useState} from "react";
import {Button} from "../../public-components/Button/Button";
import "./Flexbox.scss";
import {Link} from "react-router-dom";

export const FlexboxPage = () => {

  // Parent
  const [flexDirection, setFlexDirection] = useState('row');
  const [alignItems, setAlignItems] = useState('normal');
  const [justifyContent, setJustifyContent] = useState('normal');
  const [gap, setGap] = useState('5px');

  // Children
  const [flexGrow, setFlexGrow] = useState('0');
  
  // Child
  const [flexGrowChild, setFlexGrowChild] = useState('0');
  const [alignSelfChild, setAlignSelfChild] = useState('normal');

  const styles = {
    flexDirection: flexDirection,
    alignItems: alignItems,
    justifyContent: justifyContent,
    gap: gap,
  }

  const childrenStyles = {
    flexGrow: flexGrow,

  }

  const childStyles = {
    ...childrenStyles,
    flexGrow: flexGrowChild,
    alignSelf: alignSelfChild,
  }

  return(<div className="css-page">
    <h2>Flexbox</h2>

    <p className="description">
      Flexbox layouts are the most common way to organize content in css. They provide many options on how you can layout your content and make it take up the space you need it to or align in a certain way. Apart from complex layouts which have rows and columns and are more suitable to <Link to="/css-grid">grid layouts</Link>, <b>flexbox</b> is almost always the right choice for simplicity's sake. There are also some gotchas with <Link to="/css-grid">grid layouts</Link> - if you don't know what you're doing, you can pretty easily break it, whereas flexbox is relatively simple and straightforward. These properties are the most common and important ones to control flexbox. Play around with the different properties and you can create 95% of the different configurations you need for modern UI.
    </p>

    <div className="playground no-background flex-row" style={styles}>
      <div style={childStyles} className="flex-page_flex-child single-child">1</div>
      <div style={childrenStyles} className="flex-page_flex-child">2</div>
      <div style={childrenStyles} className="flex-page_flex-child">3</div>
      <div style={childrenStyles} className="flex-page_flex-child">4</div>
      <div style={childrenStyles} className="flex-page_flex-child">5</div>
    </div>

    <div className="content-scroll">
      <div className="playground-options flex-column">
        <h3 className="category">parent</h3>
        <div className="flex-row gap-16">
          <label>flex-direction:</label>
          <Button className={flexDirection === 'row' ? 'active' : ''} onClick={() => setFlexDirection('row')}>row</Button>
          <Button className={flexDirection === 'column' ? 'active' : ''} onClick={() => setFlexDirection('column')}>column</Button>
          <Button className={flexDirection === 'row-reverse' ? 'active' : ''} onClick={() => setFlexDirection('row-reverse')}>row-reverse</Button>
          <Button className={flexDirection === 'column-reverse' ? 'active' : ''} onClick={() => setFlexDirection('column-reverse')}>column-reverse</Button>
        </div>
        <div className="flex-row gap-16">
          <label>align-items:</label>
          <Button className={alignItems === 'start' ? 'active' : ''} onClick={() => setAlignItems('start')}>start</Button>
          <Button className={alignItems === 'center' ? 'active' : ''} onClick={() => setAlignItems('center')}>center</Button>
          <Button className={alignItems === 'end' ? 'active' : ''} onClick={() => setAlignItems('end')}>end</Button>
          <Button className={alignItems === 'normal' ? 'active' : ''} onClick={() => setAlignItems('normal')}>normal</Button>
        </div>
        <div className="flex-row gap-16">
          <label>justify-content:</label>
          <Button className={justifyContent === 'start' ? 'active' : ''} onClick={() => setJustifyContent('start')}>start</Button>
          <Button className={justifyContent === 'center' ? 'active' : ''} onClick={() => setJustifyContent('center')}>center</Button>
          <Button className={justifyContent === 'end' ? 'active' : ''} onClick={() => setJustifyContent('end')}>end</Button>
          <Button className={justifyContent === 'normal' ? 'active' : ''} onClick={() => setJustifyContent('normal')}>normal</Button>
          <Button className={justifyContent === 'space-evenly' ? 'active' : ''} onClick={() => setJustifyContent('space-evenly')}>space-evenly</Button>
          <Button className={justifyContent === 'space-around' ? 'active' : ''} onClick={() => setJustifyContent('space-around')}>space-around</Button>
          <Button className={justifyContent === 'space-between' ? 'active' : ''} onClick={() => setJustifyContent('space-between')}>space-between</Button>
        </div>
        <div className="flex-row gap-16">
          <label>gap:</label>
          <Button className={gap === '0' ? 'active' : ''} onClick={() => setGap('0')}>0</Button>
          <Button className={gap === '5px' ? 'active' : ''} onClick={() => setGap('5px')}>5px</Button>
          <Button className={gap === '10px' ? 'active' : ''} onClick={() => setGap('10px')}>10px</Button>
          <Button className={gap === '25px' ? 'active' : ''} onClick={() => setGap('25px')}>25px</Button>
        </div>
      </div>
      <hr />
      <div className="playground-options flex-column">
        <h3 className="category category-children">children</h3>
        <div className="flex-row gap-16">
          <label>flex-grow:</label>
          <Button className={flexGrow === '0' ? 'active' : ''} onClick={() => setFlexGrow('0')}>0</Button>
          <Button className={flexGrow === '1' ? 'active' : ''} onClick={() => setFlexGrow('1')}>1</Button>
          <Button className={flexGrow === '2' ? 'active' : ''} onClick={() => setFlexGrow('2')}>2</Button>
        </div>
      </div>
      <hr />
      <div className="playground-options flex-column">
        <h3 className="category category-child">child</h3>
        <div className="flex-row gap-16">
          <label>flex-grow:</label>
          <Button className={flexGrowChild === '0' ? 'active' : ''} onClick={() => setFlexGrowChild('0')}>0</Button>
          <Button className={flexGrowChild === '1' ? 'active' : ''} onClick={() => setFlexGrowChild('1')}>1</Button>
          <Button className={flexGrowChild === '2' ? 'active' : ''} onClick={() => setFlexGrowChild('2')}>2</Button>
        </div>
        <div className="flex-row gap-16">
          <label>align-self:</label>
          <Button className={alignSelfChild === 'start' ? 'active' : ''} onClick={() => setAlignSelfChild('start')}>start</Button>
          <Button className={alignSelfChild === 'center' ? 'active' : ''} onClick={() => setAlignSelfChild('center')}>center</Button>
          <Button className={alignSelfChild === 'end' ? 'active' : ''} onClick={() => setAlignSelfChild('end')}>end</Button>
          <Button className={alignSelfChild === 'normal' ? 'active' : ''} onClick={() => setAlignSelfChild('normal')}>normal</Button>
        </div>
      </div>

    </div>

  </div>)
}