import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const SelectCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
      </div>
    </>
  )
}

const componentReact =
`import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  const [selected, setSelected] = useState(null);

  const options = [
    {label: 'One', value: '1'},
    {label: 'Two', value: '2'},
  ]

  const onChange = (value) => {
    setSelected(value);
  }

  return (
    <Select
      options={options}
      onChange={onChange}
      width='200px'
      selected={selected}
    />
  )
}
/** This is a simple example of how the component works **/


export const Select = ({
  options,
  onChange,
  width = '200px',
  selected,
}) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selected) {
      onChange(selected);
    } else {
      onChange(options[0]);
    }
  },[]);

  const clickHandler = (option) => {
    setIsOpen(false);
    onChange(option);
  }

  const randomStr = Math.floor(Math.random() * 9999);

  return (
    <div
      className={\`cc-select\${isOpen ? ' cc-select-open' : ''}\`}
      aria-labelledby={'select-label-' + randomStr}
      aria-activedescendant={'option-' + randomStr + selected?.value.replace(/\W/g, '')}
      style={{width: width}}
      role="listbox"
    >
      <div
        className="cc-select-element"
        tabIndex="0"
        role="button"
        id={'select-label-' + randomStr}
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen(!isOpen);
          }
        }}
      >
        {selected ? selected.label : 'Select an option'}
      </div>
      <div
        className="cc-select-overlay"
        onClick={() => setIsOpen(false)}
        role="presentation"
        aria-hidden="true"
      />
      <div className="cc-select-options" role="presentation">
        {options.map((option) => (
          <div
            className={\`cc-select-option \${option.value === selected?.value ? 'cc-selected' : ''}\`}
            onKeyDown={(e) => e.key === 'Enter' && clickHandler(option)}
            onClick={() => clickHandler(option)}
            key={\`option-\${option.value.replace(/\s/g, '')}\`}
            aria-selected={option.value === selected?.value}
            role="option"
            tabIndex="0"
            id={'option-' + randomStr + option.value.replace(/\W/g, '')}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
}`;

const componentHtml =
``;

const componentCss =
`/* Select */
.cc-select {
  height: 32px;
  background: #FFF;
  border: 1px solid #000;
  position: relative;
  cursor: pointer;
  background-image: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M18.581 9.058c-0.523-0.535-1.252-0.577-1.891 0l-4.69 4.496-4.69-4.496c-0.64-0.577-1.369-0.535-1.889 0-0.523 0.534-0.49 1.436 0 1.938 0.487 0.502 5.634 5.402 5.634 5.402 0.26 0.268 0.602 0.402 0.944 0.402s0.684-0.134 0.947-0.402c0 0 5.144-4.901 5.634-5.402 0.491-0.502 0.523-1.404 0-1.938z"/></svg>');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: calc(100% - 8px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cc-select-open {
  background-image: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" transform="scale(1, -1)"><path fill="currentColor" d="M18.581 9.058c-0.523-0.535-1.252-0.577-1.891 0l-4.69 4.496-4.69-4.496c-0.64-0.577-1.369-0.535-1.889 0-0.523 0.534-0.49 1.436 0 1.938 0.487 0.502 5.634 5.402 5.634 5.402 0.26 0.268 0.602 0.402 0.944 0.402s0.684-0.134 0.947-0.402c0 0 5.144-4.901 5.634-5.402 0.491-0.502 0.523-1.404 0-1.938z"/></svg>');
}

.cc-select-overlay {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.cc-select-open .cc-select-overlay {
  display: block;
}

.cc-select-open .cc-select-options {
  display: block;
}

.cc-select select {
  position: absolute;
  left: -9999px;
}

.cc-select-element {
  z-index: 100;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
}

.cc-select-element,
.cc-select-option {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
}

.cc-select-options {
  display: none;
  position: absolute;
  top: 100%;
  background: #FFF;
  width: 100%;
  color: #000;
  z-index: 10;
  border: 1px solid #000;
  margin-left: -1px;
}

.cc-select-option:hover {
  background: #EFEFEF;
}`;

const componentJs =
``;