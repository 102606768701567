import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const CarouselCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
        
      </div>
    </>
  )
}



const componentReact =
`import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = ({}) => {

  return (
    <Carousel fullWidth transitionStyle="fade">
      <img width="auto" src="https://clean-components.com/img/carousel/real-estate-1.jpg" />
      <img width="auto" src="https://clean-components.com/img/carousel/real-estate-2.jpg" />
      <img width="auto" src="https://clean-components.com/img/carousel/real-estate-3.jpg" />
      <img width="auto" src="https://clean-components.com/img/carousel/real-estate-4.jpg" />
    </Carousel>
  )
}
/** This is a simple example of how the component works **/


export const Carousel = React.memo(({
  children,
  height = 400, // In px
  gap = 16, // In px
  transitionTimer = 0.8, // Duration of transitions in seconds
  autoTimer = 4, // Seconds between auto-transitions
  fullWidth = false, // Children need to be the same ratio
  buttonStyle = 1, // 1 or 2
  transitionStyle = 'slide', // 'slide' or 'fade'
  className = '',
}) => {
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [totalWidth, setTotalWidth] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [active, setActive] = useState(0);
  const [totalHeight, setTotalHeight] = useState(height);
  const [loaded, setLoaded] = useState(false);
  const [ratio, setRatio] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [resizing, setResizing] = useState(false);
  const [delayActive, setDelayActive] = useState(null);

  const contentRef = useRef();
  const childRefs = useRef(children.map(() => React.createRef()));
  const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setResizing(true);
      setDelayActive(setTimeout(() => setDelayActive(null), autoTimer * 1000));
    };

    window.addEventListener('resize', handleResize);
    const autoInt = autoTimer && setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, autoTimer * 1000);
    return () => {
      clearInterval(autoInt);
      window.removeEventListener('resize', handleResize);
    }
  },[])

  useEffect(() => {
    if(count > 0 && !delayActive) navigate('right');
  },[count]);

  useEffect(() => {
    const childNodes = contentRef.current.childNodes;
    let childData = [];
    let calculatedTotalWidth = 0;
    setActive(0);
    setLoaded(false);
    contentRef.current?.style.setProperty('--transition-speed', transitionTimer);

    childNodes.forEach((el, index) => {
      const width = el.offsetWidth;
      const left = index === 0 ? 0 : childData[index - 1].left + (fullWidth ? contentRef.current.offsetWidth : childData[index - 1].width) + gap;
      childData.push({width, left, index, ref: childRefs.current[index]});
      calculatedTotalWidth += width + gap;
    });
    setItems(childData);
    setTotalWidth(calculatedTotalWidth - gap);
    
    setTimeout(() => {
      contentRef.current?.style.setProperty('--transition-speed', 0);
      setLoaded(true);
    }, transitionTimer * 1500);
  }, [children]);

  useEffect(() => {
    setTotalHeight(fullWidth ? contentRef.current.offsetWidth / ratio : height);
  }, [ratio, windowWidth]);

  const navigate = (dir) => {
    if (animating || document.hidden) return;
    setAnimating(true);
    let newItems = [...items];
    const oldActive = active;

    if (dir === 'right') {
      setActive((oldActive + 1) % items.length);

      newItems.forEach((item) => {
        item.ref.style.transition = \`left \${transitionTimer}s ease-in-out\`;
        item.left -= items[oldActive].width + gap;
      });
      
      setTimeout(() => {
        newItems[oldActive].ref.style.transition = 'none';
        newItems[oldActive].left = totalWidth - items[oldActive].width;
        setAnimating(false);
        !resizing && setItems(newItems);
        
      }, transitionTimer * 1000);
    } else if (dir === 'left') {
      const newActive = oldActive === 0 ? items.length - 1 : oldActive - 1;
      setActive(newActive);

      newItems.forEach((item, index) => {
        if(index === newActive) {
          item.ref.style.left = -items[newActive].width - gap + 0 + 'px';

          setTimeout(() => {
            newItems[newActive].ref.style.transition = \`left \${transitionTimer}s ease-in-out\`;
            newItems[newActive].ref.style.left = '0';
            item.left = 0;
          },isFirefox ? 15 : 0.1)
        } else {
          item.ref.style.transition = \`left \${transitionTimer}s ease-in-out\`;
          item.left += items[newActive].width + gap;
        }
      });

      newItems[newActive].ref.style.transition = 'none';
      !resizing && setItems(newItems);
      setTimeout(() => {
        setAnimating(false);
      }, transitionTimer * 1000);
    }
  };

  const handleClick = (dir) => {
    navigate(dir);
    setDelayActive(setTimeout(() => {
      setDelayActive(null);
    }, autoTimer * 1500));
  }

  const addedClasses =
    \`\${fullWidth ? ' cc-carousel-full-width' : ''}\` +
    \`\${buttonStyle === 2 ? ' cc-carousel-flex-btns' : ''}\` +
    \`\${className ? \` \${className}\` : ''}\` +
    \`\${transitionStyle === 'fade' ? ' cc-carousel-fade' : ''}\`;

  return (
    <section
      style={{height: totalHeight + 'px'}}
      className={\`cc-carousel\${addedClasses}\`}
      aria-live="polite"
    >
      <button onClick={() => handleClick('left')}
        className="cc-carousel-nav cc-carousel-nav-left"
        aria-label="Previous Slide"
      ></button>
      <div className="cc-carousel-frame">
        <div ref={contentRef} className={\`cc-carousel-content\${loaded ? ' cc-carousel-loaded' : ''}\`}>
          {children.map((child, index) => {
            return React.cloneElement(child, {
              ref: el => childRefs.current[index] = el,
              key: index,
              height: 'auto',
              onLoad: (e) => index === 0 && setRatio(e.target.naturalWidth / e.target.naturalHeight),
              style: {
                ...child.props.style,
                opacity: index === active || transitionStyle === 'slide' ? '1' : '0.5', 
                left: items[index] ? items[index].left + 'px' : '0px',
              },
              'aria-current': items[index] ? items[index].isActive : false,
            });
          })}
        </div>
      </div>
      <button onClick={() => handleClick('right')}
        className="cc-carousel-nav cc-carousel-nav-right"
        aria-label="Next Slide"
      ></button>
    </section>
  );
});

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  gap: PropTypes.number,
  transitionTimer: PropTypes.number,
  autoTimer: PropTypes.number,
  fullWidth: PropTypes.bool,
  buttonStyle: PropTypes.oneOf([1, 2]),
  transitionStyle: PropTypes.oneOf(['slide', 'fade']),
  className: PropTypes.string,
}`

const componentHtml =
``;

const componentCss =
`/* Carousel */
.cc-carousel {
  display: flex;
  -webkit-display: flex;
  margin-bottom: 32px;
  position: relative;
}

.cc-carousel-frame {
  overflow: hidden;
  flex: 1;
  -webkit-flex: 1;
}

.cc-carousel-full-width .cc-carousel-content > * {
  width: 100% !important;
}

.cc-carousel-nav {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  min-width: 40px;
  max-width: 40px;
  padding: 0;
  position: absolute;
  top: 0;
  height: 100%;
  cursor: pointer;
  border: none;
  background-color: rgba(255,255,255,0.2);
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.cc-carousel-nav:hover {
  background-color: rgba(255,255,255,0.5);
}

.cc-carousel-nav-left {
  background-image: url("data:image/svg+xml,%3Csvg id='icon-keyboard_arrow_left' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.422 16.594l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z' fill='%23ffffff'/%3E%3C/svg%3E");
}

.cc-carousel-nav-right {
  background-image: url("data:image/svg+xml,%3Csvg id='icon-keyboard_arrow_right' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.578 16.594l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z' fill='%23ffffff'/%3E%3C/svg%3E");
  right: 0;
}

.cc-carousel-flex-btns .cc-carousel-frame {
  padding: 0 16px;
}

.cc-carousel-flex-btns .cc-carousel-nav {
  position: relative;
  border: 1px solid #000;
}

.cc-carousel-flex-btns .cc-carousel-nav-left {
  background-image: url("data:image/svg+xml,%3Csvg id='icon-keyboard_arrow_left' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.422 16.594l-1.406 1.406-6-6 6-6 1.406 1.406-4.594 4.594z'/%3E%3C/svg%3E");
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-border-radius: 8px 0 0 8px; /* Safari and Chrome */
  -moz-border-radius: 8px 0 0 8px; /* Firefox */
}

.cc-carousel-flex-btns .cc-carousel-nav-right {
  background-image: url("data:image/svg+xml,%3Csvg id='icon-keyboard_arrow_right' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.578 16.594l4.594-4.594-4.594-4.594 1.406-1.406 6 6-6 6z'/%3E%3C/svg%3E");
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  -webkit-border-radius: 0 8px 8px 0; /* Safari and Chrome */
  -moz-border-radius: 0 8px 8px 0; /* Firefox */
}

.cc-carousel-fade .cc-carousel-content > *  {
  transition: opacity 1.0s ease-in !important;
}

.cc-carousel-content {
  display: flex;
  gap: 16px;
  height: 100%;
  position: relative;
}

.cc-carousel-content::after {
  content: '';
  position: absolute;
  background: #fff;
  opacity: 1;
  transition: opacity 0.8s ease-in-out;
  top: 0;
  right: -16px;
  bottom: 0;
  left: -16px;
}

.cc-carousel-content.cc-carousel-loaded::after {
  opacity: 0;
}

.cc-carousel-content > * {
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}`;

const componentJs =
``;