import PropTypes from 'prop-types';

import "./Checkbox.scss";
import {useState} from 'react';
import {CheckboxCode} from './CheckboxCode';

export const Checkbox = ({
  className,
  onChange,
  label,
  checked = false,
  disabled,
}) => {

  const [isChecked, setIsChecked] = useState(checked);

  const addedClasses =
    `${className ? ` ${className}` : ''}` +
    `${disabled ? ' disabled' : ''}`;

  const handleChange = () => {
    onChange(!isChecked);
    setIsChecked(!isChecked);
  }
    
  return (<>
    <label
      className={`cc-checkbox ${addedClasses}`}
      role="checkbox"
      aria-checked={checked}
    >
      <input
        type="checkbox"
        className="cc-checkbox-input"
        checked={isChecked}
        onChange={(e) => {
          handleChange(e.target.checked);
        }}
        disabled={disabled}
        role="presentation"
        aria-hidden="true"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleChange();
          }
        }}
      />
      <div className="cc-checkbox-box">
        <div
          className="cc-checkbox-icon"
          role="presentation"
          aria-hidden="true"
        />
      </div>
      <span className="cc-checkbox-label">{label}</span>
    </label>
  </>
)};

Checkbox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}

export const Checkboxes = () => {

  const handleChange = () => {
    console.log('checkbox works')
  }

  return (<>
    <div className="component-area">
      <h2>Checkbox</h2>
      <p className="description">Use the Checkbox component for boolean style selection for a form or page option. This component includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>className</i>, <i>onChange</i>, <i>label</i>, <i>checked</i>, and <i>disabled</i>.</p>
      <Checkbox
        onChange={handleChange}
        label="This is a checkbox"
        checked={false}
      />
    </div>
    <CheckboxCode />
  </>
)};
