import {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import "./Slideout.scss";
import "../Button/Button.scss";
import {SlideoutCode} from "./SlideoutCode";

export const Slideout = ({
  title,
  btnText,
  children,
  className,
  isOpen = false,
  width = 500,
  id,
}) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen); 
  },[isOpen]);

  const addedClasses =
    `${className ? ` ${className}` : ''}` +
    `${open ? ' cc-slideout-open' : ' cc-slideout-closed'}`;

  const slideoutId = id || 'slideout-' + Math.floor(Math.random() * 9999999);

  const slideoutComponent = (
    ReactDOM.createPortal(
      <div className={`cc-slideout-container${addedClasses}`} id={slideoutId}>

        <button
          className="cc-slideout-overlay"
          onClick={() => setOpen(false)}
          aria-label="Close Slideout"
        />
        <div className="cc-slideout" style={{width: `${width}px`}}>

          <div className="cc-slideout-header">
            <h3 className="cc-slideout-title">{title}</h3>
            <button
              className="cc-slideout-close"
              onClick={() => setOpen(false)}
              aria-label="Close Slideout"
            />
          </div>

          <div className="cc-slideout-body">
            {children}
          </div>

        </div>

      </div>,
      document.body
    )
  );

  return (<>
    <button
      className="cc-open-slideout cc-button shrink"
      onClick={() => setOpen(!open)}
      aria-haspopup="dialog"
      aria-controls={slideoutId}
    >
      {btnText}
    </button>
    {slideoutComponent}
  </>
)};

Slideout.propTypes = {
  title: PropTypes.string,
  btnText: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  width: PropTypes.number,
  id: PropTypes.string,
}

export const Slideouts = () => {

  return (<>
    <div className="component-area">
      <h2>Slideout</h2>
      <p className="description">Use the Slideout component to implement slide-out overlays. Slideouts enhance user engagement by providing a convenient way to present additional information or actions. This component also includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>title</i>, <i>btnText</i>, <i>children</i>, <i>className</i>, <i>isOpen</i>, and <i>width</i>.</p>
      <Slideout btnText="Click Me" title="My Slideout">
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
      </Slideout>
    </div>
    <SlideoutCode />
  </>
)};
