import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const SlideoutCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
      </div>
    </>
  )
}

const componentReact =
`import {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Slideout
      btnText="Click Me"
      title="My Slideout"
    >
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
    </Slideout>
  )
}
/** This is a simple example of how the component works **/


export const Slideout = ({
  title,
  btnText,
  children,
  className,
  isOpen = false,
  width = 500,
  id,
}) => {

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen); 
  },[isOpen]);

  const addedClasses =
    \`\${className ? \` \${className}\` : ''}\` +
    \`\${open ? ' cc-slideout-open' : ' cc-slideout-closed'}\`;

  const slideoutId = id || 'slideout-' + Math.floor(Math.random() * 9999999);

  const slideoutComponent = (
    ReactDOM.createPortal(
      <div className={\`cc-slideout-container\${addedClasses}\`} id={slideoutId}>

        <button
          className="cc-slideout-overlay"
          onClick={() => setOpen(false)}
          aria-label="Close Slideout"
        />
        <div className="cc-slideout" style={{width: \`\${width}px\`}}>

          <div className="cc-slideout-header">
            <h3 className="cc-slideout-title">{title}</h3>
            <button
              className="cc-slideout-close"
              onClick={() => setOpen(false)}
              aria-label="Close Slideout"
            />
          </div>

          <div className="cc-slideout-body">
            {children}
          </div>

        </div>

      </div>,
      document.body
    )
  );

  return (<>
    <button
      className="cc-open-slideout cc-button shrink"
      onClick={() => setOpen(!open)}
      aria-haspopup="dialog"
      aria-controls={slideoutId}
    >
      {btnText}
    </button>
    {slideoutComponent}
  </>
)};

Slideout.propTypes = {
  title: PropTypes.string,
  btnText: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  width: PropTypes.number,
  id: PropTypes.string,
}`;

const componentHtml =
``;

const componentCss =
`/* Slideout */
.cc-slideout-overlay {
  border: none;
  background: rgba(0,0,0,0.2);
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cc-slideout {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: #FFF;
  color: #000;
  box-shadow: 0 0 10px -6px #000;
  z-index: 10;
  padding: 32px;
  transform: translateX(100%);
  transition: 0.3s ease-in-out;

  @media screen and (max-width: 500px) {
    width: auto !important;
    left: 0;
  }
}

.cc-slideout-open .cc-slideout-overlay {
  display: block;
}

.cc-slideout-open .cc-slideout {
  transform: translateX(0);
}

.cc-slideout-closed .cc-slideout-overlay {
  display: none;
}

.cc-slideout-dialog .cc-slideout-body {
  padding: 8px 36px 8px 12px;
}

.cc-slideout-header {
  border-bottom: 1px solid #000;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.cc-slideout-body {
  padding: 16px;
  overflow: auto;
  height: calc(100% - 16px);
}

.cc-slideout-body *:last-child {
  margin-bottom: 0;
}

.cc-slideout-title {
  flex: 1;
}

.cc-slideout-close{
  background: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>');
  width: 24px;
  height: 24px;
  border: none;
}`;

const componentJs =
``;