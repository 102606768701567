import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const AccordionCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea language="js" title="React" code={componentReact} />
            <CodeArea language="css" title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
        
      </div>
    </>
  )
}



const componentReact =
`import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Accordion
      title="My Accordion"
      className
      autoOpen={false}
      align="split"
    >
      This is my accordion content.
    </Accordion>
  )
}
/** This is a simple example of how the component works **/


export const Accordion = ({
  title,
  children,
  className,
  autoOpen = true,
  align = 'split',
}) => {

  const contentRef = useRef();
  const parentRef = useRef();
  const [open, setOpen] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [height, setHeight] = useState(null);

  useEffect(() => {

    if(autoOpen) {
      setHeight(contentRef.current.clientHeight);
    } else {
      setHeight(0);
      setOpen(false);
    }

    const nextSibling = parentRef.current.nextElementSibling;

    if(!nextSibling?.classList.contains('cc-accordion')) {
      setIsLast(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  },[]);

  const handleResize = () => setHeight(contentRef.current.clientHeight);

  const toggleOpen = () => {
    const contentHeight = contentRef.current.clientHeight;
    setHeight(open ? 0 : contentHeight);
    setOpen(!open);
  }

  const addedClasses =
    \`\${open ? '' : ' cc-collapsed'}\` +
    \`\${isLast ? ' cc-accordion-last' : ''}\` +
    \`\${align ? \` cc-accordion-\${align}-align\` : ''}\` +
    \`\${' '+className || ''}\`;

  return (
    <>
      <div
        ref={parentRef}
        className={\`cc-accordion\${addedClasses}\`}
        aria-labelledby="accordion-heading"
      >
        <button
          className="cc-accordion-header"
          onClick={() => toggleOpen()}
          aria-expanded={open ? 'true' : 'false'}
          aria-controls="accordion-section"
        >
          <span className="cc-accordion-header-icon"></span>
          <span className="cc-accordion-title">{title}</span>
        </button>
        <div style={{height: \`\${height}px\`}} className="cc-accordion-body">
          <div ref={contentRef} className="cc-accordion-content" role="region">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  autoOpen: PropTypes.bool,
  align: PropTypes.oneOf(['right', 'left', 'split']),
}`

const componentHtml =
``;

const componentCss =
`/* Accordion */
.cc-accordion:last-child {
  margin-bottom: 16px;
}

.cc-accordion {
  overflow: hidden;
  background: #FFF;
  color: #000;
  border: 1px solid #000;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.cc-accordion + .cc-accordion {
  border-top: 1px solid #000;
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.cc-accordion-right-align .cc-accordion-header {
  flex-direction: row-reverse;
}

.cc-accordion-split-align .cc-accordion-header {
  justify-content: space-between;
  flex-direction: row-reverse;
}

.cc-accordion-last {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.cc-accordion.cc-collapsed .cc-accordion-header-icon {
  transform: rotate(180deg);
}

.cc-accordion-header {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 8px 12px;
  gap: 8px;
  font-size: 16px;
}

.cc-accordion-header-icon {
  width: 24px;
  height: 24px;
  background-image: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.581 14.942c-0.523 0.535-1.252 0.577-1.891 0l-4.69-4.496-4.69 4.496c-0.64 0.577-1.369 0.535-1.889 0-0.523-0.534-0.49-1.436 0-1.938 0.487-0.502 5.634-5.402 5.634-5.402 0.26-0.268 0.602-0.402 0.944-0.402s0.684 0.134 0.947 0.402c0 0 5.144 4.901 5.634 5.402 0.491 0.502 0.523 1.404 0 1.938z"/></svg>');
  background-repeat: no-repeat;
  background-size: 24px;
  transition: 0.3s ease;
}

.cc-accordion-body {
  transition: 0.3s ease;
}

.cc-accordion-content {
  padding: 16px 16px 20px;
  border-top: 1px solid #000;
}`;

const componentJs =
``;
