import {useState} from "react";
import {Button} from "../../public-components/Button/Button";
import "./Grid.scss";
import {Checkbox} from "../../public-components/Checkbox/Checkbox";


export const GridPage = () => {
  
  
  const [gridTemplateRowsOn, setGridTemplateRowsOn] = useState(true);
  const [gridTemplateColumnsOn, setGridTemplateColumnsOn] = useState(true);
  const [rowGapOn, setRowGapOn] = useState(true);
  const [columnGapOn, setColumnGapOn] = useState(true);
  const [justifyContentOn, setJustifyContentOn] = useState(true);
  // const [gridTemplateRowsOn, setGridTemplateRowsOn] = useState(true);
  // const [gridTemplateRowsOn, setGridTemplateRowsOn] = useState(true);
  // const [gridTemplateRowsOn, setGridTemplateRowsOn] = useState(true);
  // const [gridTemplateRowsOn, setGridTemplateRowsOn] = useState(true);

  
  
  
  
  
  

  // Parent
  const [gridTemplateRows, setGridTemplateRows] = useState('1fr 1fr');
  const [gridTemplateColumns, setGridTemplateColumns] = useState('100px 100px 100px');
  const [rowGap, setRowGap] = useState('8px');
  const [columnGap, setColumnGap] = useState('8px');
  const [justifyContent, setJustifyContent] = useState('start');


  // Children
  // const [flexGrow, setFlexGrow] = useState('0');
  
  // Child
  // const [flexGrowChild, setFlexGrowChild] = useState('0');
  // const [alignSelfChild, setAlignSelfChild] = useState('normal');

  const styles = {
    gridTemplateRows: gridTemplateRowsOn ? gridTemplateRows : '',
    gridTemplateColumns: gridTemplateColumnsOn ? gridTemplateColumns : '',
    rowGap: rowGapOn ? rowGap : '',
    columnGap: columnGapOn ? columnGap : '',
    justifyContent: justifyContentOn ? justifyContent : '',
  }

  // const childStyles = {
  //   ...childrenStyles,
  //   flexGrow: flexGrowChild,
  //   alignSelf: alignSelfChild,
  // }

  return(<div className="css-page grid-page">
    <h2>Grid</h2>

    <p className="description">
      Here is some info about text properties
    </p>

    <div className="playground no-background flex-row">
      <div class="wrapper" style={styles}>
        <div class="grid-item grid-item-1">1</div>
        <div class="grid-item grid-item-2">2</div>
        <div class="grid-item grid-item-3">3</div>
        <div class="grid-item grid-item-4">4</div>
      </div>
    </div>

    <div className="content-scroll">
      <div className="playground-options flex-column">
        <h3 className="category">parent</h3>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => setGridTemplateRowsOn(!gridTemplateRowsOn)} label="grid-template-rows:" checked={gridTemplateRowsOn} />
          <Button className={gridTemplateRows === '50px 50px' ? 'active' : ''} onClick={() => setGridTemplateRows('50px 50px')}>50px 50px</Button>
          <Button className={gridTemplateRows === '75px 75px' ? 'active' : ''} onClick={() => setGridTemplateRows('75px 75px')}>75px 75px</Button>
          <Button className={gridTemplateRows === '1fr 1fr' ? 'active' : ''} onClick={() => setGridTemplateRows('1fr 1fr')}>1fr 1fr</Button>
        </div>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => setGridTemplateColumnsOn(!gridTemplateColumnsOn)} label="grid-template-columns:" checked={gridTemplateColumnsOn} />
          <Button className={gridTemplateColumns === '100px 100px 100px' ? 'active' : ''} onClick={() => setGridTemplateColumns('100px 100px 100px')}>100px 100px 100px</Button>
          <Button className={gridTemplateColumns === '200px 200px 200px' ? 'active' : ''} onClick={() => setGridTemplateColumns('200px 200px 200px')}>200px 200px 200px</Button>
          <Button className={gridTemplateColumns === '1fr 1fr 1fr' ? 'active' : ''} onClick={() => setGridTemplateColumns('1fr 1fr 1fr')}>1fr 1fr 1fr</Button>
        </div>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => setRowGapOn(!rowGapOn)} label="row-gap:" checked={rowGapOn} />
          <Button className={rowGap === '0' ? 'active' : ''} onClick={() => setRowGap('0')}>0</Button>
          <Button className={rowGap === '8px' ? 'active' : ''} onClick={() => setRowGap('8px')}>8px</Button>
          <Button className={rowGap === '16px' ? 'active' : ''} onClick={() => setRowGap('16px')}>16px</Button>
          <Button className={rowGap === '24px' ? 'active' : ''} onClick={() => setRowGap('24px')}>24px</Button>
        </div>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => setColumnGapOn(!columnGapOn)} label="column-gap:" checked={columnGapOn} />
          <Button className={columnGap === '0' ? 'active' : ''} onClick={() => setColumnGap('0')}>0</Button>
          <Button className={columnGap === '8px' ? 'active' : ''} onClick={() => setColumnGap('8px')}>8px</Button>
          <Button className={columnGap === '16px' ? 'active' : ''} onClick={() => setColumnGap('16px')}>16px</Button>
          <Button className={columnGap === '24px' ? 'active' : ''} onClick={() => setColumnGap('24px')}>24px</Button>
        </div>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => setJustifyContentOn(!justifyContentOn)} label="justify-content:" checked={justifyContentOn} />
          <Button className={justifyContent === 'start' ? 'active' : ''} onClick={() => setJustifyContent('start')}>start</Button>
          <Button className={justifyContent === 'center' ? 'active' : ''} onClick={() => setJustifyContent('center')}>center</Button>
          <Button className={justifyContent === 'end' ? 'active' : ''} onClick={() => setJustifyContent('end')}>end</Button>
          <Button className={justifyContent === 'space-evenly' ? 'active' : ''} onClick={() => setJustifyContent('space-evenly')}>space-evenly</Button>
          <Button className={justifyContent === 'space-around' ? 'active' : ''} onClick={() => setJustifyContent('space-around')}>space-around</Button>
          <Button className={justifyContent === 'space-between' ? 'active' : ''} onClick={() => setJustifyContent('space-between')}>space-between</Button>
        </div>
      </div>
      <hr />
      <div className="playground-options flex-column">
        <h3 className="category category-child-1">child 1</h3>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => {}} label="grid-template-rows:" checked={false} />
          {/* <Button className={flexGrow === '0' ? 'active' : ''} onClick={() => setFlexGrow('0')}>0</Button> */}
        </div>
      </div>
      <hr />
      <div className="playground-options flex-column">
        <h3 className="category category-child-2">child 2</h3>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => {}} label="grid-template-rows:" checked={false} />
          {/* <Button className={flexGrowChild === '0' ? 'active' : ''} onClick={() => setFlexGrowChild('0')}>0</Button> */}
        </div>
      </div>
      <hr />
      <div className="playground-options flex-column">
        <h3 className="category category-child-3">child 3</h3>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => {}} label="grid-template-rows:" checked={false} />
          {/* <Button className={flexGrowChild === '0' ? 'active' : ''} onClick={() => setFlexGrowChild('0')}>0</Button> */}
        </div>
      </div>
      <hr />
      <div className="playground-options flex-column">
        <h3 className="category category-child-4">child 4</h3>
        <div className="flex-row gap-16">
          <Checkbox onChange={() => {}} label="grid-template-rows:" checked={false} />
          {/* <Button className={flexGrowChild === '0' ? 'active' : ''} onClick={() => setFlexGrowChild('0')}>0</Button> */}
        </div>
      </div>
    </div>
  </div>
)}