import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const ButtonCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
        
      </div>
    </>
  )
}


const componentReact =

`import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Button
      className
      size
      onClick={() => {}}
      fullWidth="false"
      align
      isLink
      href=""
      type="primary"
    >
      This is a button
    </Button>
  )
}
/** This is a simple example of how the component works **/


export const Button = ({
  children,
  className,
  size = 'medium',
  onClick = () => {},
  fullWidth,
  isLink = false,
  href = null,
  type = 'primary',
}) => {

  const addedClasses =
    \` \${className ? \` \${className}\` : ''}\` +
    \`\${size ? \` cc-button-\${size}\` : ''}\` +
    \`\${type ? \` cc-button-\${type}\` : ''}\` +
    \`\${fullWidth ? ' cc-button-full-width' : ''}\`;

  const Element = isLink ? 'a' : 'button';

  return (<>
    <Element
      tabIndex="0"
      className={\`cc-button\${addedClasses}\`}
      onClick={onClick}
      href={href}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && isLink) {
          onClick();
        }
      }}
    >
      {children}
    </Element>
  </>
)};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.oneOf(['primary','secondary','transparent']),
}`

const componentHtml =
``;

const componentCss =
`/* Button */
.cc-button {
  font-size: 18px;
  padding: 8px 16px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 8px;
  cursor: pointer;
  border: 1px solid #000;
  transition: 0.25s;
  justify-content: center;
  background-color: #FFF;
  text-decoration: none;
  color: #000;
}

.cc-button:hover {
  background-color: #33beb8;
  color: #fff;
}

.cc-button path {
  transition: 0.25s;
}

.cc-button:hover path {
  fill: #fff;
}

.cc-button-full-width {
  width: 100%;
  box-sizing: border-box;
}

.cc-button-small {
  font-size: 16px;
  padding: 4px 16px;
}

.cc-button-large {
  font-size: 24px;
}

.cc-button-left {
  justify-content: flex-start;
}

.cc-button-right {
  justify-content: flex-end;
}

.cc-button-primary {
  background: #33beb8;
  color: #FFF;
}

.cc-button-primary:hover {
  background: #FFF;
  color: #000;
}

.cc-button-transparent {
  border: none;
  background: none;
  color: #000;
  padding: 4px 0;
}

.cc-button-transparent:hover {
  text-decoration: underline;
  background: none;
  color: #000;
}`;

const componentJs =
``;
