import React, {useState} from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';

export const Editor = ({codeText, language, onClickHandler}) => {

  const [code, setCode] = useState(
    codeText
  );

  return (
    <div className="editor-container">
      <CodeEditor
        value={code}
        language={language}
        placeholder="Please enter JS code."
        onChange={(evn) => setCode(evn.target.value)}
        onClick={() => onClickHandler}
        padding={15}
      />
    </div>
)};


