import {useEffect, useRef} from "react";
import PropTypes from 'prop-types';
import "./Table.scss";
import {TableCode} from "./TableCode";

export const Table = ({
  width,
  maxHeight,
  data,
  className,
}) => {

  const tableRef = useRef();

  useEffect(() => {
    const thCount = tableRef.current.querySelectorAll('th').length;
    const gridStyle = `repeat(${thCount}, minmax(150px, 2fr))`;
    tableRef.current.style.gridTemplateColumns = gridStyle;
  },[]);

  const hasRowLabels = data.cols[0] === '';
  const classNames = 
    `${hasRowLabels ? ' cc-table-row-labels' : ''}` +
    `${className ? ` ${className}` : ''}`;

  return (
    <table
      style={{width: width, maxHeight: maxHeight}}
      ref={tableRef}
      className={`cc-table${classNames}`}
    >
      <thead>
        <tr>
          {data.cols.map(col => 
            <th>{col}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.rows.map(row =>
          <tr>
            {row.map(col =>
              <td>{col}</td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  )
};

Table.propTypes = {
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    cols: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    ).isRequired,
  })
}

export const Tables = () => {

  return (<>
    <div className="component-area">
      <h2>Table</h2>
      <p className="description">Use the Table component to present a lot of information in an organized, efficient way. Cols defines your column headers. Row labels are optional. If you prefer them, cols should start with with '' and each rows child array should start with the row label.<br /><br />Props include <i>width</i>, <i>maxHeight</i>, and <i>data</i>.</p>
      <Table data={tableData} maxHeight="300px" />
    </div>
    <TableCode />
  </>
)}

export const tableData = {
  cols: [
    '',
    'Walter White',
    'Jesse Pinkman',
    'Saul Goodman',
    'Gustavo Fring',
    'Hank Schrader',
    'Mike Ehrmantraut',
    'Skyler White',
    'Walter Jr.',
    'Tuco Salamanca',
  ],
  rows: [
    [
      'Traits',
      'Intelligent',
      'Resourceful',
      'Slick',
      'Strategic',
      'Determined',
      'Loyal',
      'Complex',
      'Disabled',
      'Unpredictable',
    ],
    [
      'Personality',
      'Manipulative',
      'Emotional',
      'Charming',
      'Calm',
      'Brave',
      'Reserved',
      'Depressed',
      'Adolescent',
      'Aggressive',
    ],
    [
      'Actions',
      'Drug Manufacturer',
      'Drug Dealer',
      'Lawyer',
      'Drug Lord',
      'DEA Agent',
      'Enforcer',
      'Stay-at-home Mom',
      'Student',
      'Street Thug',
    ],
    [
      'Motivation',
      'Provide for Family',
      'Survive',
      'Financial Gain',
      'Expand Drug Empire',
      'Fight Crime',
      'Protect Family',
      'Escape',
      'Rebellion',
      'Power',
    ],
    [
      'Relationships',
      'Strained',
      'Close Friendships',
      'Professional',
      'Manipulative',
      'Bonded',
      'Loyal',
      'Complicated',
      'Family Ties',
      'Hostile',
    ],
    [
      'Fate',
      'Death',
      'Survives',
      'Survives',
      'Death',
      'Death',
      'Survives',
      'Survives',
      'Survives',
      'Death',
    ],
    [
      'Skills',
      'Chemistry',
      'Street-smart',
      'Legal Maneuvering',
      'Business Acumen',
      'Investigation',
      'Tactical',
      'Accounting',
      'Video Games',
      'Violence',
    ],
    [
      'Quirks',
      'Cancer Diagnosis',
      'Drug Addiction',
      'Slogan: "Better Call Saul"',
      'Pollos Hermanos Franchise',
      'Minerals Obsession',
      'Cleanliness',
      'Infidelity',
      'Cerebral Palsy',
      'Unpredictable Behavior',
    ],
    [
      'Morality',
      'Deteriorates',
      'Redemption',
      'Gray Area',
      'Questionable',
      'Righteous',
      'Code of Honor',
      'Compromised',
      'Innocent',
      'Amoral',
    ],
  ]
}
