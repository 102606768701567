import React, {useState} from 'react';
import PropTypes from 'prop-types';

import "./Range.scss";
import {RangeCode} from './RangeCode';

export const Range = ({
  textPosition = 'bottom',
  min = 0,
  max = 100,
  step = 1,
  defaultValue,
  primaryColor = '#2b9348',
  secondaryColor = 'lightgrey',
  width,
  onChange = () => {},
  thumbSize = 15,
  id,
}) => {
  const [value, setValue] = useState(defaultValue || max/2);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const addedClasses = textPosition ? ` cc-range-text-${textPosition}` : '';

  const parentStyles = {
    '--cc-range-primary-color': primaryColor,
    '--cc-range-secondary-color': secondaryColor,
    '--cc-range-width': width,
    '--cc-range-thumb-size': thumbSize.toString()+'px',
  };

  const rangeId = id || 'range-' + Math.floor(Math.random() * 9999999);

  return (
    <div className={`cc-range-slider${addedClasses}`} style={parentStyles}>
      <input
        type="range"
        min={min}
        max={max}
        step={step.toString()}
        value={value}
        onChange={handleChange}
        className="cc-range-slider-input"
        style={{width: width}}
        aria-describedby={rangeId}
      />
      <output id={rangeId} htmlFor="original-slider">
        {value}
      </output>
    </div>
  );
};

Range.propTypes = {
  textPosition: PropTypes.oneOf(['top','right','bottom','left']),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  width: PropTypes.string,
}

export const Ranges = () => {

  return (<>
    <div className="component-area">
      <h2>Range</h2>
      <p className="description">Use the Range component to allow users to fine tune their input or preferences. Can easily be styled and customized to your application color scheme. This component also includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>textPosition</i>, <i>min</i>, <i>max</i>, <i>step</i>, <i>defaultValue</i>, <i>primaryColor</i>, <i>secondaryColor</i>, and <i>width</i>.</p>
      <div className="flex-row flex-space-between gap-16">
        <Range textPosition="top" />
        <Range textPosition="right" thumbSize={20} primaryColor="blue" secondaryColor="Red" />
        <Range textPosition="bottom" defaultValue={68} />
        <Range textPosition="left" />
      </div>
    </div>
    <RangeCode />
  </>
)};