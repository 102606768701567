import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import "./Popover.scss";
import {PopoverCode} from './PopoverCode';
import {Input} from '../Input/Input';
import {Button} from '../Button/Button';


export const Popover = ({
  className,
  isOpen = true,
  id = 'cc-popover-1',
  children,
  position = 'bottom',
  type = 'info',
  content = <>&nbsp;</>,
  showOnce,
}) => {

  const TYPE_COLORS = {
    info: '#40a4d8',
    success: '#33beb8',
    error: '#ee657a',
    warning: '#f9a228',
    blank: '#ffffff'
  };

  const [open, setOpen] = useState(isOpen);

  const containerRef = useRef(null);
  const popoverRef = useRef(null);

  const addedClasses = 
    `${type ? ` cc-popover-${type}` : ''}`+
    `${position ? ` cc-popover-${position}` : ''}`+
    `${className ? ` ${className}` : ''}`;

  useEffect(() => {
    setOpen(isOpen);
  },[isOpen]);

  useEffect(() => {
    if (!containerRef.current || !containerRef.current.firstChild) return;
    const target = containerRef.current.firstChild;
    if (!target || !popoverRef) return;
    
    containerRef.current.style.width = target.offsetWidth+'px';
    popoverRef.current?.style.setProperty('--popover-color', TYPE_COLORS[type]);
    popoverRef.current?.style.setProperty('--target-width', target.offsetWidth+'px');
    popoverRef.current?.style.setProperty('--target-height', target.offsetHeight+'px');

  }, [children]);

  const closePopover = () => {
    setOpen(false);

    if(showOnce) {
      let storedIds = JSON.parse(localStorage.getItem('cc-popover-ids')) || [];
      storedIds.push(id);
      localStorage.setItem('cc-popover-ids', JSON.stringify(storedIds));
    }
  }

  const storedIds = JSON.parse(localStorage.getItem('cc-popover-ids')) || [];
  if(showOnce && storedIds.includes(id)) {
    return children;
  }

  return (
    <div
      ref={containerRef}
      className="cc-popover-container"
      aria-hidden={!open}
    >
      {children}
      {open &&
        <div
          className={`cc-popover${addedClasses}`}
          ref={popoverRef}
          id={id}
          role="alert"
          aria-describedby={id}
        >
          <div className="cc-popover-content" id={id}>
            {content}
          </div>
          <button
            className="cc-popover-close"
            onClick={() => closePopover()}
            aria-label="Close Popover"
          />
        </div>
      }
    </div>
)};

Popover.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top','right','bottom','left']),
  type: PropTypes.oneOf(['info','success','error','warning','blank']),
  content: PropTypes.node,
}


export const Popovers = () => {

  return (<>
    <div className="component-area">
      <h2>Popover</h2>
      <p className="description m-bottom-64">The Popover component is used to call out information or actionable elements on the page. With the different types, you can use them for input errors, info icons, or just to let a user know what's new in an application interface. This component also includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>className</i>, <i>isOpen</i>, <i>id</i>, <i>children</i>, <i>position</i>, <i>type</i>, and <i>content</i>.</p>
      <div className="flex-row flex-space-between m-bottom-64" style={{alignItems: 'end'}}>
        <Popover id="cc-popover-1" showOnce isOpen position="right" type="info" content={<p>This is a multi-line info<br />popover. Use &lt;br /&gt; to<br />make a new line.</p>}>
          <Button type="secondary" className="cc-button">Target</Button>
        </Popover>
        <Popover id="cc-popover-2" showOnce isOpen position="top" type="error" content={<p><b>Error</b>:<br />Invalid email address.</p>}>
          <Input width="auto" label="Email" type="text" onChange={() => {}} />
        </Popover>
        <Popover showOnce id="cc-popover-3" isOpen position="bottom" type="success" content={<p>Success!</p>}>
        <div style={{whiteSpace: 'nowrap'}}>Div Target</div>
        </Popover>
        <Popover showOnce id="cc-popover-4" isOpen position="left" type="warning" content={<p><b>Warning</b>: your info has not<br />been saved.</p>}>
          <button className="cc-button">Target</button>
        </Popover>
      </div>
    </div>
    <PopoverCode />
  </>
)};