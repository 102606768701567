import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const ModalCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
      </div>
    </>
  )
}

const componentReact =
`import {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Modal
      btnText="Open Modal"
      title="My Modal"
      size="medium"
    >
      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
    </Modal>
  )
}
/** This is a simple example of how the component works **/


export const Modal = ({
  title,
  hasBtn = true,
  btnText,
  children,
  className,
  size = 'dialog',
  isOpen,
  id,
}) => {

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  },[isOpen]);

  const addedClasses =
    \`\${className ? \` \${className}\` : ''}\` +
    \`\${size ? \` cc-modal-\${size}\` : ''}\`;

  const modalId = id || 'modal-' + Math.floor(Math.random() * 9999999);

  const modalComponent = open && (
    ReactDOM.createPortal(
      <div className={\`cc-modal-container\${addedClasses}\`} id={modalId}>

        <button
          className="cc-modal-overlay"
          onClick={() => setOpen(!open)}
          aria-label="Close Modal"
        />
        <div className="cc-modal">

          {size !== 'dialog' 
            ? <div className="cc-modal-header">
              <h3 className="cc-modal-title">{title}</h3>
              <button
                className="cc-modal-close"
                onClick={() => setOpen(!open)}
                aria-label="Close Modal"
              />
            </div>
            : <button className="cc-modal-fixed-close" onClick={() => setOpen(!open)}></button>
          }
          
          <div className="cc-modal-body">
            {children}
          </div>

        </div>

      </div>,
      document.body
    )
  );

  return (<>
    {hasBtn &&
      <button
        className="cc-open-modal cc-button shrink"
        onClick={() => setOpen(!open)}
        aria-haspopup="dialog"
        aria-controls={modalId}
      >
        {btnText}
      </button>
    }
    {modalComponent}
  </>
)};

Modal.propTypes = {
  title: PropTypes.string,
  hasBtn: PropTypes.bool,
  btnText: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['dialog', 'small', 'medium', 'large', 'full']),
  isOpen: PropTypes.bool,
  id: PropTypes.string,
}`;

const componentHtml =
``;

const componentCss =
`/* Modal */
.cc-modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.cc-modal-overlay {
  border: none;
  background: rgba(0,0,0,0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Default Medium Modal */
.cc-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 800px;
  height: 600px;
  background: #FFF;
  color: #000;
  box-shadow: 0 0 10px -6px #000;
  z-index: 10;
  border-radius: 4px;
  margin: 32px;
}

/* Dialog Modal */
.cc-modal-dialog .cc-modal {
  width: 320px;
  height: auto;
  max-height: 200px;
}

.cc-modal-dialog .cc-modal-body {
  padding: 8px 36px 8px 12px;
}

/* Small Modal */
.cc-modal-small .cc-modal {
  width: 600px;
  height: 400px;
}

/* Large Modal */
.cc-modal-large .cc-modal {
  width: 1200px;
  height: 700px;
}

/* Fullscreen Modal */
.cc-modal-full .cc-modal {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
}

.cc-modal-header {
  border-bottom: 1px solid #000;
  padding: 8px 16px;
  display: flex;
  align-items: center;
}

.cc-modal-body {
  padding: 16px;
  overflow: auto;
  height: calc(100% - 16px);
}

.cc-modal-body *:last-child {
  margin-bottom: 0;
}

.cc-modal-title {
  flex: 1;
}

.cc-modal-close,
.cc-modal-fixed-close {
  background: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>');
  width: 24px;
  height: 24px;
  border: none;
}

.cc-modal-fixed-close {
  position: absolute;
  right: 8px;
  top: 8px;
}
  `;

const componentJs =
``;