import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const CheckboxCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
        
      </div>
    </>
  )
}



const componentReact =
`import PropTypes from 'prop-types';
import {useState} from 'react';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Checkbox
      className
      onChange={() => {}}
      label="Checkbox Label"
      disabled={false}
    />
  )
}
/** This is a simple example of how the component works **/


export const Checkbox = ({
  className,
  onChange,
  label,
  checked = false,
  disabled,
}) => {

  const [isChecked, setIsChecked] = useState(checked);

  const addedClasses =
    \`\${className ? \` \${className}\` : ''}\` +
    \`\${disabled ? ' disabled' : ''}\`;

  const handleChange = () => {
    onChange(!isChecked);
    setIsChecked(!isChecked);
  }
    
  return (<>
    <label
      className={\`cc-checkbox \${addedClasses}\`}
      role="checkbox"
      aria-checked={checked}
    >
      <input
        type="checkbox"
        className="cc-checkbox-input"
        checked={isChecked}
        onChange={(e) => {
          handleChange(e.target.checked);
        }}
        disabled={disabled}
        role="presentation"
        aria-hidden="true"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleChange();
          }
        }}
      />
      <div className="cc-checkbox-box">
        <div
          className="cc-checkbox-icon"
          role="presentation"
          aria-hidden="true"
        />
      </div>
      <span className="cc-checkbox-label">{label}</span>
    </label>
  </>
)};

Checkbox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
}`

const componentHtml =
``;

const componentCss =
`/* Checkbox */
.cc-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cc-checkbox-input:focus + .cc-checkbox-box {
  outline: 2px solid -webkit-focus-ring-color; /* WebKit */
  outline: 2px solid -moz-mac-focusring; /* Firefox on macOS */
  outline: 2px solid -moz-focus-inner; /* Firefox */
  outline: 2px auto -webkit-focus-ring-color;
}

.cc-checkbox.disabled .cc-checkbox-box {
  background-color: #aaa;
}

.cc-checkbox.disabled .cc-checkbox-icon {
  background-image: none;
}

.cc-checkbox * {
  cursor: pointer;
}

.cc-checkbox-input {
  position: absolute;
  opacity: 0;
}

.cc-checkbox-box {
  height: 20px;
  width: 20px;
  background-color: #FFF;
  border: 1px solid #000;
  border-radius: 4px;
}

.cc-checkbox-input:checked + .cc-checkbox-box {
  background-color: #2b7946;
}

.cc-checkbox-icon {
  width: 20px;
  height: 20px; 
  background-image: url('data:image/svg+xml;utf8,<svg class="check-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="white" d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z"></path></svg>');
}

.cc-checkbox-label {
  color: #000;
}
`;

const componentJs =
``;
