import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './Select.scss';
import {SelectCode} from './SelectCode';

export const Select = ({
  options,
  onChange,
  width = '200px',
  selected,
}) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selected) {
      onChange(selected);
    } else {
      onChange(options[0]);
    }
  },[]);

  const clickHandler = (option) => {
    setIsOpen(false);
    onChange(option);
  }

  const randomStr = Math.floor(Math.random() * 9999);

  return (
    <div
      className={`cc-select${isOpen ? ' cc-select-open' : ''}`}
      aria-labelledby={'select-label-' + randomStr}
      aria-activedescendant={'option-' + randomStr + selected?.value.replace(/\W/g, '')}
      style={{width: width}}
      role="listbox"
    >
      <div
        className="cc-select-element"
        tabIndex="0"
        role="button"
        id={'select-label-' + randomStr}
        aria-haspopup="true"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setIsOpen(!isOpen);
          }
        }}
      >
        {selected ? selected.label : 'Select an option'}
      </div>
      <div
        className="cc-select-overlay"
        onClick={() => setIsOpen(false)}
        role="presentation"
        aria-hidden="true"
      />
      <div className="cc-select-options" role="presentation">
        {options.map((option) => (
          <div
            className={`cc-select-option ${option.value === selected?.value ? 'cc-selected' : ''}`}
            onKeyDown={(e) => e.key === 'Enter' && clickHandler(option)}
            onClick={() => clickHandler(option)}
            key={`option-${option.value.replace(/\s/g, '')}`}
            aria-selected={option.value === selected?.value}
            role="option"
            tabIndex="0"
            id={'option-' + randomStr + option.value.replace(/\W/g, '')}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
}

export const Selects = () => {

  const [selected, setSelected] = useState(null);

  const options = [
    {
      label: 'One',
      value: '1',
    },
    {
      label: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#000"
            className="bi bi-heart"
            viewBox="0 0 24 24"
          >
            <path d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM11.016 6.984h1.969v6h-1.969v-6zM11.016 15h1.969v2.016h-1.969v-2.016z"></path>
          </svg>
          Two
        </>
      ),
      value: '2',
    },
  ]

  const onChange = (value) => {
    setSelected(value);
  }

  return (<>
    <div className="component-area">
      <h2>Select</h2>
      <p className="description">Use the Select component to allow users to choose from a list of options. Options can be simple strings or JSX. This component also includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>options</i>, <i>onChange</i>, and <i>width</i>.</p>
      <Select
        options={options}
        onChange={onChange}
        width='200px'
        selected={selected}
      />
    </div>
    <SelectCode />
  </>
)};