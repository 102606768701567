import {useState} from "react";
import PropTypes from 'prop-types';
import "./Toggle.scss";
import {ToggleCode} from "./ToggleCode";

export const Toggle = ({leftLabel, rightLabel, handleToggle = () => {}}) => {

  const [on, setOn] = useState(false);

  const toggle = () => {
    handleToggle(!on);
    setOn(!on);

    const codeAreas = document.querySelectorAll('.code-area');
    codeAreas.forEach(area => {
      area.classList.remove('copied');
    });
  }

  const labelId = 'label-' + Math.floor(Math.random() * 9999);

  return (
    <div className="cc-toggle-container">
      <span className="cc-toggle-label" id={labelId+'1'}>{leftLabel}</span>
        <div
          className={`cc-toggle${on ? ' cc-toggle-on' : ''}`}
          tabIndex="0"
          role="switch"
          aria-checked={on}
          aria-labelledby={labelId + '1 ' + labelId+'2'}
          onClick={() => toggle()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') toggle();
          }}
        >
          <div className="cc-toggle-indicator"></div>
        </div>
      <span className="cc-toggle-label" id={labelId+'2'}>{rightLabel}</span>
    </div>
  )
}

Toggle.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
}

export const Toggles = () => {

  const handleToggle = () => {
    
  }

  return (<>
    <div className="component-area">
      <h2>Toggle</h2>
      <p className="description">Use the Toggle component as another option for a booloan style input. This is a convenient and sometimes aesthetically preferrable way to display an option to the user. This component also includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>leftLabel</i>, <i>rightLabel</i>, and <i>handleToggle</i>.</p>
      <Toggle
        leftLabel="Left label"
        rightLabel="Right label"
        handleToggle={handleToggle}
      />
    </div>
    <ToggleCode />
  </>
)}