import React from 'react';

import "./Home.scss";

export const Home = () => {

  return (
    <div className="cc-homepage">
      <h2>About me and this site</h2>
      <p>Hello and welcome! If you want a place to copy paste clean react components from, this is it. This site is new as of 11/23 but I'm constantly updating it and adding new features so check back often to see what's new.</p>
      <p>I am a React Engineer specializing in UI and I've been in the industry for 11 years. In that time I've learned an enormous amount and have developed strong opinions about best practices and how code should be written. I believe in making code as short and efficient as possible and when it comes to UI components, that requires a deep understanding of CSS. It can be difficult to know what's necessary and what's not especially if you're using dependencies. The purpose of this site is to simplify that and educate frontend engineers on the best way to create clean UI components that can be further customized to meet the needs of your codebase. I've also included all the accessbility features to make your application compatible with accessbility standards.</p>
      <p>However, I'm not perfect. I'm sure my code can be improved and I'll never stop trying to do just that. So if you have any suggestions about improvements or components/features you'd like to see, please don't hesitate to email me at <a href="mailto:cleancomponents@gmail.com">cleancomponents@gmail.com</a>. Otherwise, copy the code for these components and try to understand what is in them because they are kind of the bare bones examples of how UI components work. Knowledge-sharing leads to better outcomes for all of us so pass it on! Updates to come.</p>
      <img width="100%" src="./img/home-bkg.jpg" />
      {/* <img width="100%" src="./img/home-bkg2.jpg" />
      <img width="100%" src="./img/home-bkg3.jpg" /> */}
      {/* <p>Hello and welcome! I am a React Engineer specializing in UI and I've been in the industry for 11 years. In that time I've learned an enormous amount and have developed strong opinions about best practices and how code should be written. One thing I have often noticed is that engineers often over-complicate html and css because they don't fully understand it. Another thing I see a lot is the overuse of dependencies. I believe there is a better way.</p>
      <p>Some engineers will either struggle to build their own components well or choose the easy way out and just install a new bloated, massive dependency, and only a fraction of the code in that dependency will be used. There is a time and a place for dependencies but when it comes to relatively simple UI components; just learn to build your own. It will save you a lot of hassle and is much more efficient. And if done in the right way, will be even better than a dependency because it will be perfectly suited to your application.</p>
      <p>And unlike this diatribe I'm subjecting you to, I keep my code clean, short, and efficient. Having said that, I'm not perfect. I'm sure my code can be improved and I'll never stop trying to do just that. So if you have any suggestions about improvements or components/features you'd like to see, please don't hesitate to email me at <a href="mailto:cleancomponents@gmail.com">cleancomponents@gmail.com</a>. Otherwise, copy the code for these components and try to understand what is in them because they are kind of the bare bones examples of how UI components work. Knowledge-sharing leads to better outcomes for all of us so pass it on!</p> */}
    </div>
  );
};
