

export const PseudoElementsPage = () => {

  return(<div className="css-page">
    <h2>Pseudo Elements</h2>
    <ul>
      <li>::after</li>
      <li>::backdrop</li>
      <li>::before</li>
      <li>::cue</li>
      <li>::cue-region</li>
      <li>::first-letter</li>
      <li>::first-line</li>
      <li>::file-selector-button</li>
      <li>::grammar-error Experimental</li>
      <li>::marker</li>
      <li>::part()</li>
      <li>::placeholder</li>
      <li>::selection</li>
      <li>::slotted()</li>
      <li>::spelling-error Experimental</li>
      <li>::target-text Experimental</li>
    </ul>
  </div>)
}