import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import './Accordion.scss';
import {AccordionCode} from './AccordionCode';

export const Accordion = ({
  title,
  children,
  className,
  autoOpen = true,
  align = 'split',
}) => {

  const contentRef = useRef();
  const parentRef = useRef();
  const [open, setOpen] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [height, setHeight] = useState(null);

  useEffect(() => {

    if(autoOpen) {
      setHeight(contentRef.current.clientHeight);
    } else {
      setHeight(0);
      setOpen(false);
    }

    const nextSibling = parentRef.current.nextElementSibling;

    if(!nextSibling?.classList.contains('cc-accordion')) {
      setIsLast(true);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  },[]);

  const handleResize = () => setHeight(contentRef.current.clientHeight);

  const toggleOpen = () => {
    const contentHeight = contentRef.current.clientHeight;
    setHeight(open ? 0 : contentHeight);
    setOpen(!open);
  }

  const addedClasses =
    `${open ? '' : ' cc-collapsed'}` +
    `${isLast ? ' cc-accordion-last' : ''}` +
    `${align ? ` cc-accordion-${align}-align` : ''}` +
    `${className || ''}`;

  return (
    <>
      <div
        ref={parentRef}
        className={`cc-accordion${addedClasses}`}
        aria-labelledby="accordion-heading"
      >
        <button
          className="cc-accordion-header"
          onClick={() => toggleOpen()}
          aria-expanded={open ? 'true' : 'false'}
          aria-controls="accordion-section"
        >
          <span className="cc-accordion-header-icon"></span>
          <span className="cc-accordion-title">{title}</span>
        </button>
        <div style={{height: `${height}px`}} className="cc-accordion-body">
          <div ref={contentRef} className="cc-accordion-content" role="region">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  autoOpen: PropTypes.bool,
  align: PropTypes.oneOf(['right', 'left', 'split']),
}

export const Accordions = () => {

  return (<>
    <div className="component-area">
      <h2 className="page-title">Accordion</h2>
      <p className="description">Use the Accordion component to organize your content into collapsible sections - a useful way to organize a lot of data in a compact space. This component includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>title</i>, <i>children</i>, <i>className</i>, <i>autoOpen</i>, and <i>align</i>.</p>
      <Accordion title="Accordion Header" autoOpen={false}>
        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
      </Accordion>
      <Accordion title="Accordion Header" autoOpen={false}>
        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
      </Accordion>
      <Accordion title="Accordion Header" autoOpen={false}>
        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
      </Accordion>
    </div>
    <AccordionCode />
  </>
)}