import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import "./Input.scss";
import {FormRow} from "../FormRow/FormRow";
import {InputCode} from "./InputCode";

export const Input = ({
  label,
  column,
  width = '100%',
  onChange,
  value = '',
  type,
  required = false,
  showErrorMsg = false,
  id,
}) => {

  const [localValue, setLocalValue] = useState(value);
  const [error, setError] = useState('');

  useEffect(() => {
    onChange(localValue);
  }, [localValue, onChange]);

  const handleChange = (e) => {

    let filteredValue = e.target.value;

    // Input is empty, remove error state
    if(filteredValue === '') {
      setError('');
      setLocalValue(filteredValue);
      return;
    }

    switch(type) {

      case 'tel':

        // Format telephone number: +12 (888) 345-6789
        filteredValue = filteredValue.replace(/\D/g, '');

        if(filteredValue.length > 12) return;

        if (filteredValue.length > 1) {
          let cc = 0;
          if(filteredValue.length === 12) cc = 1;
          let formattedNumber = `+${filteredValue.substring(0, 1+cc)} (${filteredValue.substring(1+cc, 4+cc)}) ${filteredValue.substring(4+cc,7+cc)}`;
          if(filteredValue.length > 7) formattedNumber += `-${filteredValue.substring(7+cc)}`;
          filteredValue = formattedNumber;
        } 
        break;

      case 'url':

        // Validate URL
        const pattern = new RegExp('\\b((?:[a-z][\\w-]+:(?:/{1,3}|[a-z0-9%])|www\\d{0,3}[.]|[a-z0-9.\\-]+[.][a-z]{2,4}/)(?:[^\\s()<>]+|\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\))+(?:\\(([^\\s()<>]+|(\\([^\\s()<>]+\\)))*\\)|[^\\s`!()\\[\\]{};:\'".,<>?«»“”‘’]))','i');
        const hasInvalidFirstChar = !/^[a-z]/i.test(filteredValue);
        const hasInvalidLastChar = filteredValue.slice(-1) === '.'
        const hasLessThanTwoDots = filteredValue.split('.').length - 1 < 2;
        const hasInvalidRegex = !pattern.test(filteredValue);

        if(hasInvalidFirstChar || hasInvalidLastChar || hasLessThanTwoDots || hasInvalidRegex) {
          setError('Error: Invalid URL');
        } else {
          setError('');
        }
        break;
    }

    setLocalValue(filteredValue);
  };

  const labelStyles = {
    width: column ? `calc((100% / 12) * ${column})` : width,
    ...(error && {color: 'red'}),
  }
  
  const inputStyles = {
    ...(error && {borderColor: 'red'}),
  }

  const inputId = id || 'input-' + Math.floor(Math.random() * 999999);
  const errorId = `${inputId}-error`;

  return (
    <label htmlFor={inputId}  className="cc-input" style={labelStyles}>
      <span className="cc-input-label">{label}</span>
      <input
        type={!['url','email'].includes(type) ? type : null}
        className="cc-input-element"
        value={localValue}
        onChange={handleChange}
        style={inputStyles}
        aria-required={required}
        aria-invalid={!!error}
        aria-describedby={showErrorMsg ? error : null}
        id={id || inputId}
      />
      {showErrorMsg &&
        <span
          id={errorId}
          className="cc-input-error"
          role="alert"
        >
          {error}
        </span>
      }
    </label>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  column: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  width: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  required: PropTypes.bool,
  showErrorMsg: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.oneOf([
    'color',
    'date', 
    'email',
    'file',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'text',
    'time',
    'url'
  ]),
}

export const Inputs = () => {

  const handleChange = (e) => {
    
    if(e.length > 0) {
      console.log(e)
    }
  }

  return (<>
    <div className="component-area">
      <h2>Input</h2>
      <p className="description">Use the Input component for a wide variety of input types, complete with error handling and 12 different input types. This component also includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>label</i>, <i>column</i>, <i>width</i>, <i>onChange</i>, <i>value</i>, <i>required</i>, <i>showErrorMsg</i>, <i>id</i>, and <i>type</i>.</p>
      <FormRow>
        <Input label="Color Element" column={3} width="300px" type="color" onChange={handleChange} />
        <Input label="Date Element" column={3} width="300px" type="date" onChange={handleChange} />
        <Input label="Email Element" column={3} width="300px" type="email" onChange={handleChange} />
        <Input label="File Element" column={3} width="300px" type="file" onChange={handleChange} />
      </FormRow>
      <FormRow>
        <Input label="Month Element" column={3} width="300px" type="month" onChange={handleChange} />
        <Input label="Number Element" column={3} width="300px" type="number" onChange={handleChange} />
        <Input label="Password Element" column={3} width="300px" type="password" onChange={handleChange} />
        <Input label="Search Element" column={3} width="300px" type="search" onChange={handleChange} />
      </FormRow>
      <FormRow>
        <Input label="Tel Element" column={3} width="300px" type="tel" onChange={handleChange} />
        <Input label="Text Element" column={3} width="300px" type="text" onChange={handleChange} />
        <Input label="Time Element" column={3} width="300px" type="time" onChange={handleChange} />
        <Input label="Url Element" column={3} width="300px" type="url" onChange={handleChange} />
      </FormRow>
    </div>
    <InputCode />
  </>
)}