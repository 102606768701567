import {useEffect, useRef} from "react";
import {Editor} from "./Editor/Editor";


export const CodeArea = ({title, code}) => {

  const parentRef = useRef();
  const textRef = useRef();

  const selectText = () => {
    textRef.current.select();
    document.execCommand('copy');
  }

  useEffect(() => {

    parentRef.current.addEventListener('click', function() {

      const codeAreas = document.querySelectorAll('.code-area');

      codeAreas.forEach(area => {
        area.classList.remove('copied');
      });

      this.classList.add('copied');
    });
  },[]);

  return (
    <div ref={parentRef} className="code-area flex-fill" onClick={() => selectText()}>
      <button className="code-area-copy"></button>
      <h2 className="code-area-title">{title}</h2>
      <textarea className="code-area-hidden-text" ref={textRef} rows="30" value={code} readOnly></textarea>
      <Editor
        language={title === 'CSS' ? 'css' : 'jsx'}
        codeText={code}
        onClickHandler={() => selectText}
      />
    </div>
  )
}


