import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from './Toggle';

export const ToggleCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
      </div>
    </>
  )
}

const componentReact =
`import {useState} from 'react';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Toggle
      leftLabel="Left label"
      rightLabel="Right label"
      handleToggle={() => {}}
    />
  )
}
/** This is a simple example of how the component works **/


export const Toggle = ({leftLabel, rightLabel, handleToggle = () => {}}) => {

  const [on, setOn] = useState(false);

  const toggle = () => {
    handleToggle(!on);
    setOn(!on);
  }

  const labelId = 'label-' + Math.floor(Math.random() * 9999);

  return (
    <div className="cc-toggle-container">
      <span className="cc-toggle-label" id={labelId+'1'}>{leftLabel}</span>
        <div
          className={\`cc-toggle\${on ? ' cc-toggle-on' : ''}\`}
          tabIndex="0"
          role="switch"
          aria-checked={on}
          aria-labelledby={labelId + '1 ' + labelId+'2'}
          onClick={() => toggle()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') toggle();
          }}
        >
          <div className="cc-toggle-indicator"></div>
        </div>
      <span className="cc-toggle-label" id={labelId+'2'}>{rightLabel}</span>
    </div>
  )
}

Toggle.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
}`;

const componentHtml =
``;

const componentCss =
`/* Toggle */
.cc-toggle-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.cc-toggle {
  width: 40px;
  height: 20px;
  background: #FFF;
  border: 1px solid #2d2f31;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.cc-toggle.cc-toggle-on {
  background: #FFF;
}

.cc-toggle.cc-toggle-on .cc-toggle-indicator {
  left: calc(100% - 18px);
}

.cc-toggle-indicator {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 14px;
  height: 14px;
  background: #2d2f31;
  border: 1px solid #2d2f31;
  border-radius: 50%;
  transition: 0.3s ease;
}

.cc-toggle-label {
  font-size: 14px;
}`;

const componentJs =
``;