import {BrowserRouter as Router, Route, Routes, Link, useLocation, useNavigate} from "react-router-dom";
import {Accordions} from './public-components/Accordion/Accordion';
import {Buttons} from "./public-components/Button/Button";
import {Checkboxes} from "./public-components/Checkbox/Checkbox";
import {Home} from "./components/Home/Home";
import {Example} from "./components/Test/Test";
import {Modals} from './public-components/Modal/Modal';
import {Popovers} from "./public-components/Popover/Popover";
import {Ranges} from "./public-components/Range/Range";
import {Selects} from "./public-components/Select/Select";
import {Slideouts} from "./public-components/Slideout/Slideout";
import {Tables} from './public-components/Table/Table';
import {Toggles} from "./public-components/Toggle/Toggle";
import {FormElements} from "./components/FormElements/FormElements";
import {Inputs} from "./public-components/Input/Input";
import {useEffect, useRef, useState} from "react";
import {AnimationsPage} from "./css-pages/Animations";
import {FlexboxPage} from "./css-pages/Flexbox/Flexbox";
import {FormsPage} from "./css-pages/Forms";
import {GeneralPage} from "./css-pages/General/General";
import {GridPage} from "./css-pages/Grid/Grid";
import {OverflowPage} from "./css-pages/Overflow";
import {PseudoElementsPage} from "./css-pages/PseudoElements";
import {SpecificityPage} from "./css-pages/Specificity";
import {TextPage} from "./css-pages/Text/Text";
import {ZIndexPage} from "./css-pages/ZIndex";
import {Carousels} from "./public-components/Carousel/Carousel";
import {BoxModelPage} from "./css-pages/BoxModel/BoxModel";


const NavLink = (props) => {
  
  const location = useLocation();
  const isActive = location.pathname === props.to;

  const activeClass = () => {
    if(isActive) {
      return ' active'
    } else {
      return ''
    }
  }

  return (
    <Link className={props.className+activeClass()} to={props.to}>
      {props.children}
    </Link>
  );
};


function App() {

  const [mobileNavOpen, setMobileNavOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showCSS, setShowCSS] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const sidebarToggleRef = useRef();
  const navToggleBtnRef = useRef();
  // const h1ToggleRef = useRef();

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  const showCSSNav = (show) => {
    setShowCSS(show);
    if(show) {
      navigate('/css-text');
    } else {
      navigate('/carousel');
    }
  }

  useEffect(() => {

    window.setTimeout(() => {
      if(sidebarToggleRef.current && navToggleBtnRef.current) {
        sidebarToggleRef.current.style.transition = 'transform 0.3s ease-in-out';
        navToggleBtnRef.current.style.transition = 'transform 0.3s ease-in-out';
        // h1ToggleRef.current.style.transition = 'margin 0.3s ease-in-out';
      }
    },1000);

    if (/^\/css/.test(location.pathname)) {
      setShowCSS(true);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (<>
    <div className="header pad-16 text-center">
      
      {/* <div className="header-options">
        <Popover isOpen position="left" type="info" content={<p>Questions/Comments?</p>}>
          <button className="feedback-button">
            <svg viewBox="0 0 24 24">
              <path d="M21.984 3.984v18l-3.984-3.984h-14.016q-0.797 0-1.383-0.609t-0.586-1.406v-12q0-0.797 0.586-1.383t1.383-0.586h16.031q0.797 0 1.383 0.586t0.586 1.383z" fill="#fff"></path>
            </svg>
          </button>
        </Popover>
      </div> */}
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
      <Link className="site-title" to="/">
        <img width="70px" height="auto" src="./img/logo.png" />
        <h1>
          Clean React Components
          {/* <span className="h1-toggle"> */}
            {/* <span ref={h1ToggleRef} className={`h1-toggle-1${showCSS ? ' show-css' : ''}`}>React Components</span> */}
            {/* <span>CSS</span>
          </span> */}
        </h1>
      </Link>
      {/* <Link className="about-link" to="/"></Link> */}
    </div>
    <div className="body flex-fill flex-row">

      {screenWidth < 768 && mobileNavOpen &&
        <div className="mobile-nav-overlay" onClick={() => setMobileNavOpen(!mobileNavOpen)}></div>
      }
      <div className={`sidebar flex-column pad-32${screenWidth < 768 && mobileNavOpen ? ' mobile-nav-open' : ''}`}>
        {screenWidth < 768 &&
          <button className={`mobile-nav-toggle${mobileNavOpen ? ' open' : ''}`} onClick={() => setMobileNavOpen(!mobileNavOpen)}></button>
        }
        <ul>
          <li><NavLink className="cc-button home-button basic-button" to="/">Home</NavLink></li>
          {/* <li><NavLink className="cc-button basic-button" to="/test">Test</NavLink></li> */}
          <hr></hr>
        </ul>
        <div ref={sidebarToggleRef} className={`sidebar-toggler${showCSS ? ' show-css' : ''}`}>
          <ul>
            <li><NavLink className="cc-button basic-button" to="/accordion">Accordion</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/button">Button</NavLink></li>
            <li className="nav-new"><NavLink className="cc-button basic-button" to="/carousel">Carousel</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/checkbox">Checkbox</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/input">Input</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/modal">Modal</NavLink></li>
            <li className="nav-new"><NavLink className="cc-button basic-button" to="/popover">Popover</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/range">Range</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/select">Select</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/slideout">Slideout</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/table">Table</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/toggle">Toggle</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/toggle">Test</NavLink></li>
            {/*
              Ideas for components
              * Toast (from top and bottom)
            */}
          </ul>
          {/* <ul> */}
            {/* <li><NavLink className="cc-button basic-button" to="/css-animations">Animations</NavLink></li> */}
            {/* <li><NavLink className="cc-button basic-button" to="/css-box-model">Box Model</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/css-flexbox">Flexbox</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/css-general">General</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/css-text">Text</NavLink></li>
            <li><NavLink className="cc-button basic-button" to="/css-grid">Grid</NavLink></li> */}
            {/*
              Ideas:
              
                * Grid Layout
                * Responsive Design
                * Selectors and Pseudo-classes
                * Animations and Transitions
                * Positioning
                * Media Queries
            
            */}
            {/* <li><NavLink className="cc-button basic-button" to="/css-overflow">Overflow</NavLink></li> */}
            {/* <li className="font-17"><NavLink className="cc-button basic-button" to="/css-pseudo-elements">Pseudo Els</NavLink></li> */}
            {/* <li><NavLink className="cc-button basic-button" to="/css-responsiveness">Responsiveness</NavLink></li> */}
            {/* <li><NavLink className="cc-button basic-button" to="/css-specificity">Specificity</NavLink></li> */}
            {/* <li><NavLink className="cc-button basic-button" to="/css-z-index">Z-Index</NavLink></li> */}
          {/* </ul> */}
        </div>

        {/** Rainbow Nav Toggler */}
        {/* <div className="nav-toggle-container">
          <button type="secondary" className="cc-button basic-button nav-toggle glow-on-hover" onClick={() => (screenWidth > 767) && showCSSNav(!showCSS)}>
            <div className="nav-toggle-wrapper">
              <div ref={navToggleBtnRef} className={`nav-toggle-frame ${showCSS ? 'show-css' : ''}`}>
                <div>CSS<svg viewBox="0 0 24 24"><path d="M15 5.016l-1.406 1.406 4.594 4.594h-16.172v1.969h16.172l-4.594 4.594 1.406 1.406 6.984-6.984z"></path></svg></div>
                <div><svg viewBox="0 0 24 24"><path d="M15 5.016l-1.406 1.406 4.594 4.594h-16.172v1.969h16.172l-4.594 4.594 1.406 1.406 6.984-6.984z" transform="scale(-1, 1) translate(-24, 0)"></path></svg>React</div>
              </div>
            </div>
          </button>
        </div> */}
      </div>
      <div className="content flex-fill pad-32">

        <div className={showCSS ? 'css-area' : 'component-area'}>
          <Routes>
            <Route path="/test" element={<div style={{padding: '100px'}}><Example /></div>} />

            {/** REACT ROUTES */}
            <Route path="/" element={<Home />} />
            <Route path="/accordion" element={<Accordions />} />
            <Route path="/button" element={<Buttons />} />
            <Route path="/carousel" element={<Carousels />} />
            <Route path="/checkbox" element={<Checkboxes />} />
            <Route path="/input" element={<Inputs />} />
            <Route path="/modal" element={<Modals />} />
            <Route path="/popover" element={<Popovers />} />
            <Route path="/range" element={<Ranges />} />
            <Route path="/select" element={<Selects />} />
            <Route path="/slideout" element={<Slideouts />} />
            <Route path="/table" element={<Tables />} />
            <Route path="/toggle" element={<Toggles />} />
            <Route path="/form-elements" element={<FormElements />} />

            {/** CSS ROUTES */}
            <Route path="/css-animations" element={<AnimationsPage />} />
            <Route path="/css-box-model" element={<BoxModelPage />} />
            
            <Route path="/css-flexbox" element={<FlexboxPage />} />
            <Route path="/css-general" element={<GeneralPage />} />
            <Route path="/css-grid" element={<GridPage />} />
            <Route path="/css-overflow" element={<OverflowPage />} />
            <Route path="/css-pseudo-elements" element={<PseudoElementsPage />} />
            {/* <Route path="/css-responsiveness" element={<ResponsivenessPage />} /> */}
            <Route path="/css-specificity" element={<SpecificityPage />} />
            <Route path="/css-text" element={<TextPage />} />
            <Route path="/css-z-index" element={<ZIndexPage />} />
          </Routes>
        </div>
      </div>
      <div className="sidebar-ads-container">
        {/* Nav Sidebar Ad 1 */}
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6054837554097597" crossOrigin="anonymous"></script>
        <ins className="adsbygoogle ad-1" style={{display: 'inline-block', width: '127px', height: '300px'}} data-ad-client="ca-pub-6054837554097597" data-ad-slot="8950719917"></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6054837554097597"
            crossOrigin="anonymous"></script>
        {/* Nav Sidebar Ad 2 */}
        <ins className="adsbygoogle ad-2" style={{display: 'inline-block', width: '127px', height: '300px'}} data-ad-client="ca-pub-6054837554097597" data-ad-slot="4981940340"></ins>
        <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
      </div>
    </div>
  </>);
}

export default App;
