import {CodeArea} from "../../components/CodeArea";


export const GeneralPage = () => {

  return(<div className="css-page">
    <h2>General</h2>
    <p className="description">General CSS is something you might want to define right when you start a project. The purpose is to both reset the default things you don't like with css and to make some helpful definitions that will benefit you for the life of the project. Doing so will keep your code cleaner and more efficient. I started out with a boilerplate by Matt McAdams' <a target="blank" href="https://github.com/MattMcAdams/CSS-Boilerplate">CSS Boilerplate</a> so shout out to him and by all means if you need something more advanced, use his. What I wanted to do is trim it down to only the most basic and most useful things. I also made some changes to it and added a bunch of utility classes, but basically the point was to give you something digestible if you're new to writing css so you can understand how it works.<br /><br />One important feature to note is the use of variables. Css variables are defined by the syntax <b>--my-variable: my-value</b> and referenced by <b>var(--my-variable)</b>. This can help you ensure consistency throuout your application. For example the first variable we define is setting the default text color with <b>--text-color: #000000;</b> and then later referenced by <b>color: var(--text-color);</b> so that if your default text color ever changes, you can just change it in one place and your whole application will be effectively updated.</p>
    <CodeArea title="React" code={generalCSS} />
  </div>)
}

const generalCSS =
`:root {
      
  /* Colors */
  --text-color: #000000;
  --heading-color: #333333;
  --link-color: #0074E0;
  --invalid-color: #CD2727;
  --disabled-color: #CCCCCC;
  --page-background: #FFFFFF;
  --focus: #58D158;
  
  /* Spacing */
  --header-height: 5rem;
  --sidebar-width: 15rem;
  --gap: 0.5rem;
  --base-padding: 0.5rem;
  
  /* Fonts */
  --font-main: 'Helvetica', Arial, sans-serif;
  --font-1: 1rem/1.5rem var(--font-main);
  --font-2: 1.5rem/2rem var(--font-main);
  --font-3: 2rem/2.5rem var(--font-main);
  --font-4: 3rem/3.5rem var(--font-main);
  --font-5: 4rem/4.5rem var(--font-main);
}

/* Resets */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, img, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

[disabled] {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

:focus-visible {
  outline-color: var(--focus);
  outline-offset: 3px;
  outline-width: 2px;
}

body {
  font: var(--font-1);
  color: var(--text-color);
  background: var(--page-background);
}

/* Text Elements */
h1 {
  font: var(--font-5);
  font-weight: 700;
}

h2 {
  font: var(--font-4);
  font-weight: 600;
}

h3 {
  font: var(--font-3);
  font-weight: 600;
}

h4 {
  font: var(--font-2);
  font-weight: 700;
}

h5, label, th, legend {
  font-weight: 600;
}

strong, b {
  font-weight: bold;
}

em, cite {
  font-style: italic;
}

/* Classes */
.disabled {
  color: var(--disabled-color);
}

.error {
  color: var(--invalid-color);
}

.margin-0 {
  margin: 0;
}

.margin-4 {
  margin: 0.25rem;
}

.margin-8 {
  margin: 0.5rem;
}

.margin-16 {
  margin: 1rem;
}

.margin-32 {
  margin: 2rem;
}

.margin-64 {
  margin: 4rem;
}

.padding-0 {
  padding: 0;
}

.padding-4 {
  padding: 0.25rem;
}

.padding-8 {
  padding: 0.5rem;
}

.padding-16 {
  padding: 1rem;
}

.padding-32 {
  padding: 2rem;
}

.padding-64 {
  padding: 4rem;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.flex-fill {
  flex: 1;
}

.grid {
  display: grid;
}

.transform-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`;