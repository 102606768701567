import PropTypes from 'prop-types';

import "./Button.scss";
import {ButtonCode} from './ButtonCode';

export const Button = ({
  children,
  className,
  size = 'medium',
  onClick = () => {},
  fullWidth,
  isLink = false,
  href = null,
  type = 'primary',
}) => {

  const addedClasses =
    `${className ? ` ${className}` : ''}` +
    `${size ? ` cc-button-${size}` : ''}` +
    `${type ? ` cc-button-${type}` : ''}` +
    `${fullWidth ? ' cc-button-full-width' : ''}`;

  const Element = isLink ? 'a' : 'button';

  return (<>
    <Element
      tabIndex="0"
      className={`cc-button${addedClasses}`}
      onClick={onClick}
      href={href}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && isLink) {
          onClick();
        }
      }}
    >
      {children}
    </Element>
  </>
)};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.oneOf(['primary','secondary','transparent']),
}

export const Buttons = () => {

  const handleClick = () => {
    alert('Button clicked!')
  }

  return (<>
    <div className="component-area">
      <h2>Button</h2>
      <p className="description">Use the Button component to incorporate interactive elements or links into your website. This component includes keyboard navigation accessibility.<br /><br />Props include <i>children</i>, <i>className</i>, <i>size</i>, <i>onClick</i>, <i>fullWidth</i>, <i>align</i>, <i>isLink</i>, <i>href</i>, and <i>type</i>.</p>
      <Button className="m-bottom-16" size="small" onClick={handleClick} type="transparent">Small Transparent Button</Button>
      <Button className="m-bottom-16" size="small" onClick={handleClick} isLink>Medium Primary Button</Button>
      <Button size="large" fullWidth onClick={handleClick} isLink type="secondary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="#000"
          className="bi bi-heart"
          viewBox="0 0 24 24"
        >
          <path d="M12 20.016q3.281 0 5.648-2.367t2.367-5.648-2.367-5.648-5.648-2.367-5.648 2.367-2.367 5.648 2.367 5.648 5.648 2.367zM12 2.016q4.125 0 7.055 2.93t2.93 7.055-2.93 7.055-7.055 2.93-7.055-2.93-2.93-7.055 2.93-7.055 7.055-2.93zM11.016 6.984h1.969v6h-1.969v-6zM11.016 15h1.969v2.016h-1.969v-2.016z"></path>
        </svg>
        Large Full Width Secondary Button
      </Button>
    </div>
    <ButtonCode />
  </>
)};
