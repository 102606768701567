import React, {useEffect, useRef, useState} from "react";
import {CarouselCode} from "./CarouselCode";
import PropTypes from 'prop-types';
import "./Carousel.scss";

export const Carousel = React.memo(({
  children,
  height = 400, // In px
  gap = 16, // In px
  transitionTimer = 0.8, // Duration of transitions in seconds
  autoTimer = 4, // Seconds between auto-transitions
  fullWidth = false, // Children need to be the same ratio
  buttonStyle = 1, // 1 or 2
  transitionStyle = 'slide', // 'slide' or 'fade'
  className = '',
}) => {
  const [count, setCount] = useState(0);
  const [items, setItems] = useState([]);
  const [totalWidth, setTotalWidth] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [active, setActive] = useState(0);
  const [totalHeight, setTotalHeight] = useState(height);
  const [loaded, setLoaded] = useState(false);
  const [ratio, setRatio] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [resizing, setResizing] = useState(false);
  const [delayActive, setDelayActive] = useState(null);

  const contentRef = useRef();
  const childRefs = useRef(children.map(() => React.createRef()));
  const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setResizing(true);
      setDelayActive(setTimeout(() => setDelayActive(null), autoTimer * 1000));
    };

    window.addEventListener('resize', handleResize);
    const autoInt = autoTimer && setInterval(() => {
      setCount(prevCount => prevCount + 1);
    }, autoTimer * 1000);
    return () => {
      clearInterval(autoInt);
      window.removeEventListener('resize', handleResize);
    }
  },[])

  useEffect(() => {
    if(count > 0 && !delayActive) navigate('right');
  },[count]);

  useEffect(() => {
    const childNodes = contentRef.current.childNodes;
    let childData = [];
    let calculatedTotalWidth = 0;
    setActive(0);
    setLoaded(false);
    contentRef.current?.style.setProperty('--transition-speed', transitionTimer);

    childNodes.forEach((el, index) => {
      const width = el.offsetWidth;
      const left = index === 0 ? 0 : childData[index - 1].left + (fullWidth ? contentRef.current.offsetWidth : childData[index - 1].width) + gap;
      childData.push({width, left, index, ref: childRefs.current[index]});
      calculatedTotalWidth += width + gap;
    });
    setItems(childData);
    setTotalWidth(calculatedTotalWidth - gap);
    
    setTimeout(() => {
      contentRef.current?.style.setProperty('--transition-speed', 0);
      setLoaded(true);
    }, transitionTimer * 1500);
  }, [children]);

  useEffect(() => {
    setTotalHeight(fullWidth ? contentRef.current.offsetWidth / ratio : height);
  }, [ratio, windowWidth]);

  const navigate = (dir) => {
    if (animating || document.hidden) return;
    setAnimating(true);
    let newItems = [...items];
    const oldActive = active;

    if (dir === 'right') {
      setActive((oldActive + 1) % items.length);

      newItems.forEach((item) => {
        item.ref.style.transition = `left ${transitionTimer}s ease-in-out`;
        item.left -= items[oldActive].width + gap;
      });
      
      setTimeout(() => {
        newItems[oldActive].ref.style.transition = 'none';
        newItems[oldActive].left = totalWidth - items[oldActive].width;
        setAnimating(false);
        !resizing && setItems(newItems);
        
      }, transitionTimer * 1000);
    } else if (dir === 'left') {
      const newActive = oldActive === 0 ? items.length - 1 : oldActive - 1;
      setActive(newActive);

      newItems.forEach((item, index) => {
        if(index === newActive) {
          item.ref.style.left = -items[newActive].width - gap + 0 + 'px';

          setTimeout(() => {
            newItems[newActive].ref.style.transition = `left ${transitionTimer}s ease-in-out`;
            newItems[newActive].ref.style.left = '0';
            item.left = 0;
          },isFirefox ? 15 : 0.1)
        } else {
          item.ref.style.transition = `left ${transitionTimer}s ease-in-out`;
          item.left += items[newActive].width + gap;
        }
      });

      newItems[newActive].ref.style.transition = 'none';
      !resizing && setItems(newItems);
      setTimeout(() => {
        setAnimating(false);
      }, transitionTimer * 1000);
    }
  };

  const handleClick = (dir) => {
    navigate(dir);
    setDelayActive(setTimeout(() => {
      setDelayActive(null);
    }, autoTimer * 1500));
  }

  const addedClasses =
    `${fullWidth ? ' cc-carousel-full-width' : ''}` +
    `${buttonStyle === 2 ? ' cc-carousel-flex-btns' : ''}` +
    `${className ? ` ${className}` : ''}` +
    `${transitionStyle === 'fade' ? ' cc-carousel-fade' : ''}`;

  return (
    <section
      style={{height: totalHeight + 'px'}}
      className={`cc-carousel${addedClasses}`}
      aria-live="polite"
    >
      <button onClick={() => handleClick('left')}
        className="cc-carousel-nav cc-carousel-nav-left"
        aria-label="Previous Slide"
      ></button>
      <div className="cc-carousel-frame">
        <div ref={contentRef} className={`cc-carousel-content${loaded ? ' cc-carousel-loaded' : ''}`}>
          {children.map((child, index) => {
            return React.cloneElement(child, {
              ref: el => childRefs.current[index] = el,
              key: index,
              height: 'auto',
              onLoad: (e) => index === 0 && setRatio(e.target.naturalWidth / e.target.naturalHeight),
              style: {
                ...child.props.style,
                opacity: index === active || transitionStyle === 'slide' ? '1' : '0.5', 
                left: items[index] ? items[index].left + 'px' : '0px',
              },
              'aria-current': items[index] ? items[index].isActive : false,
            });
          })}
        </div>
      </div>
      <button onClick={() => handleClick('right')}
        className="cc-carousel-nav cc-carousel-nav-right"
        aria-label="Next Slide"
      ></button>
    </section>
  );
});

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  gap: PropTypes.number,
  transitionTimer: PropTypes.number,
  autoTimer: PropTypes.number,
  fullWidth: PropTypes.bool,
  buttonStyle: PropTypes.oneOf([1, 2]),
  transitionStyle: PropTypes.oneOf(['slide', 'fade']),
  className: PropTypes.string,
}

export const Carousels = () => {

  return(<>
    <div className="component-area">
      <h2>Carousel</h2>
      <p className="description">The Carousel component allows you to show an animating slider with whatever children you want to pass to it. It dynamically animates the correct amount for each item and reorders on each animation so that you can go backward or forward infinitely. The prop 'autoTimer' can be set to 0 to prevent automated scrolling and the speed of the transition can be changed with transitionTimer.<br /><br />The functionality of this component far exceeds most react carousels out there including bootstrap. Admittedly it isn't very simple but in order to achieve seamless left/right scrolling and full responsiveness, some advanced concepts were necessary. I recommend looking into this code because some of the concepts I had to use are next level React UI. Enjoy!<br /><br />Props include <i>children</i>, <i>height</i>, <i>gap</i>, <i>transitionTimer</i>, <i>autoTimer</i>, <i>fullWidth</i>, <i>buttonStyle</i>, <i>transitionStyle</i>, and <i>className</i>.</p>
      <Carousel height={300} fullWidth transitionStyle="fade" autoTimer={5}>
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-1.jpg" />
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-2.jpg" />
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-3.jpg" />
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-4.jpg" />
      </Carousel>
      <Carousel height={300} fullWidth transitionStyle="slide" buttonStyle={2}>
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-1.jpg" />
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-2.jpg" />
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-3.jpg" />
        <img width="auto" src="https://clean-components.com/img/carousel/real-estate-4.jpg" />
      </Carousel>
      <Carousel height={300} autoTimer={null} buttonStyle={2}>
        <div style={{width: '100px', border: '1px solid black', padding: '16px'}}>
          <h3>Item 0</h3>
          <hr></hr>
          <p>The carousel is set up to accept whatever you'd like as children.</p>
        </div>
        <div style={{width: '200px', border: '1px solid black', padding: '16px'}}>
          <h3>Item 1</h3>
          <hr></hr>
          <p>The carousel is set up to accept whatever you'd like as children.</p>
        </div>
        <div style={{width: '300px', border: '1px solid black', padding: '16px'}}>
          <h3>Item 2</h3>
          <hr></hr>
          <p>The carousel is set up to accept whatever you'd like as children.</p>
        </div>
        <div style={{width: '400px', border: '1px solid black', padding: '16px'}}>
          <h3>Item 3</h3>
          <hr></hr>
          <p>The carousel is set up to accept whatever you'd like as children.</p>
        </div>
        <div style={{width: '500px', border: '1px solid black', padding: '16px'}}>
          <h3>Item 4</h3>
          <hr></hr>
          <p>The carousel is set up to accept whatever you'd like as children.</p>
        </div>
      </Carousel>
    </div>
    <CarouselCode />
  </>
)}