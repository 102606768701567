import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const RangeCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
      </div>
    </>
  )
}

const componentReact =
`import React, {useState} from 'react';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Range textPosition="top" />
  )
}
/** This is a simple example of how the component works **/


export const Range = ({
  textPosition = 'bottom',
  min = 0,
  max = 100,
  step = 1,
  defaultValue,
  primaryColor = '#2b9348',
  secondaryColor = 'lightgrey',
  width,
  onChange = () => {},
  thumbSize = 15,
  id,
}) => {
  const [value, setValue] = useState(defaultValue || max/2);

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  const addedClasses = textPosition ? \` cc-range-text-\${textPosition}\` : '';

  const parentStyles = {
    '--cc-range-primary-color': primaryColor,
    '--cc-range-secondary-color': secondaryColor,
    '--cc-range-width': width,
    '--cc-range-thumb-size': thumbSize.toString()+'px',
  };

  const rangeId = id || 'range-' + Math.floor(Math.random() * 9999999);

  return (
    <div className={\`cc-range-slider\${addedClasses}\`} style={parentStyles}>
      <input
        type="range"
        min={min}
        max={max}
        step={step.toString()}
        value={value}
        onChange={handleChange}
        className="cc-range-slider-input"
        style={{width: width}}
        aria-describedby={rangeId}
      />
      <output id={rangeId} htmlFor="original-slider">
        {value}
      </output>
    </div>
  );
};

Range.propTypes = {
  textPosition: PropTypes.oneOf(['top','right','bottom','left']),
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  width: PropTypes.string,
}`;

const componentHtml =
``;

const componentCss =
`/* Range */
.cc-range-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cc-range-slider-input {
  cursor: pointer;
  flex: 1;
}

.cc-range-slider output {
  font-size: 1.2rem;
  color: #333;
}

.cc-range-text-top {
  flex-direction: column-reverse;
}

.cc-range-text-right {
  flex-direction: row;
  gap: 8px;
}

.cc-range-text-left {
  flex-direction: row-reverse;
  gap: 8px;
}

.cc-range-slider-input {
  -webkit-appearance: none;
  width: 100%;
}

.cc-range-slider-input::-webkit-slider-runnable-track {
  width: var(--cc-range-width);
  height: 5px;
  background: var(--cc-range-secondary-color);
  border: none;
  border-radius: 3px;
}

.cc-range-slider-input::-moz-range-track {
  width: var(--cc-range-width);
  height: 5px;
  background: var(--cc-range-secondary-color);
  border: none;
  border-radius: 3px;
}

.cc-range-slider-input::-moz-range-thumb {
  -webkit-appearance: none;
  border: none;
  height: var(--cc-range-thumb-size);
  width: var(--cc-range-thumb-size);
  border-radius: 50%;
  background: var(--cc-range-primary-color);
  margin-top: calc(3px + var(--cc-range-thumb-size) / -2);
}

.cc-range-slider-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: var(--cc-range-thumb-size);
  width: var(--cc-range-thumb-size);
  border-radius: 50%;
  background: var(--cc-range-primary-color);
  margin-top: calc(3px + var(--cc-range-thumb-size) / -2);
}`;

const componentJs =
``;