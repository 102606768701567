import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const PopoverCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
      </div>
    </>
  )
}

const componentReact =
`import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = ({}) => {

  return (
    <Popover
      isOpen
      position="bottom"
      type="success"
      content={<p>Click here!<br />Your progress has been saved.</p>}
      showOnce
      id="unique-id-1"
      children
    >
      <button className="cc-button">Submit</button>
    </Popover>
  )
}
/** This is a simple example of how the component works **/


export const Popover = ({
  className,
  isOpen = true,
  id = 'cc-popover-1',
  children,
  position = 'bottom',
  type = 'info',
  content = <>&nbsp;</>,
  showOnce,
}) => {

  const TYPE_COLORS = {
    info: '#40a4d8',
    success: '#33beb8',
    error: '#ee657a',
    warning: '#f9a228',
    blank: '#ffffff'
  };

  const [open, setOpen] = useState(isOpen);

  const containerRef = useRef(null);
  const popoverRef = useRef(null);

  const addedClasses = 
    \`\${type ? \` cc-popover-\${type}\` : ''}\`+
    \`\${position ? \` cc-popover-\${position}\` : ''}\`+
    \`\${className ? \` \${className}\` : ''}\`;

  useEffect(() => {
    setOpen(isOpen);
  },[isOpen]);

  useEffect(() => {
    if (!containerRef.current || !containerRef.current.firstChild) return;
    const target = containerRef.current.firstChild;
    if (!target || !popoverRef) return;
    
    containerRef.current.style.width = target.offsetWidth+'px';
    popoverRef.current?.style.setProperty('--popover-color', TYPE_COLORS[type]);
    popoverRef.current?.style.setProperty('--target-width', target.offsetWidth+'px');
    popoverRef.current?.style.setProperty('--target-height', target.offsetHeight+'px');

  }, [children]);

  const closePopover = () => {
    setOpen(false);

    if(showOnce) {
      let storedIds = JSON.parse(localStorage.getItem('cc-popover-ids')) || [];
      storedIds.push(id);
      localStorage.setItem('cc-popover-ids', JSON.stringify(storedIds));
    }
  }

  const storedIds = JSON.parse(localStorage.getItem('cc-popover-ids')) || [];
  if(showOnce && storedIds.includes(id)) {
    return children;
  }

  return (
    <div
      ref={containerRef}
      className="cc-popover-container"
      aria-hidden={!open}
    >
      {children}
      {open &&
        <div
          className={\`cc-popover\${addedClasses}\`}
          ref={popoverRef}
          id={id}
          role="alert"
          aria-describedby={id}
        >
          <div className="cc-popover-content" id={id}>
            {content}
          </div>
          <button
            className="cc-popover-close"
            onClick={() => closePopover()}
            aria-label="Close Popover"
          />
        </div>
      }
    </div>
)};

Popover.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top','right','bottom','left']),
  type: PropTypes.oneOf(['info','success','error','warning','blank']),
  content: PropTypes.node,
}`;

const componentHtml =
``;

const componentCss =
`// Popover
.cc-popover-container {
  position: relative;
}

.cc-popover-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cc-popover-close {
  background: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>');
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border: none;
}

.cc-popover {
  position: absolute;
  z-index: 10;
  box-sizing: border-box;
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 14px;
  
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #FFF;
  background-color: var(--popover-color);
  box-shadow: 0 0 10px -5px #000;
  min-width: 100px;
}

.cc-popover-content > * {
  margin: 0;
  white-space: nowrap;
}

.cc-popover-blank {
  color: #000;
}

.cc-popover-blank .cc-popover-close {
  background-image: url('data:image/svg+xml;utf8,<svg class="header-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black"><path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z"></path></svg>');
}

.cc-popover::before,
.cc-popover::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid transparent;
}

// Top
.cc-popover-top {
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.cc-popover-top::before {
  top: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
  border-top: 8px solid #ddd;
}

.cc-popover-top::after {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-top: 8px solid var(--popover-color);
}

// Right
.cc-popover-right {
  left: calc(var(--target-width) + 10px);
  top: calc(var(--target-height)/2);
  transform: translateY(-50%);
}

.cc-popover-right::before {
  top: 50%;
  right: calc(100% + 1px);
  transform: translateY(-50%);
  border-right: 8px solid #ddd;
}

.cc-popover-right::after {
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  border-right: 8px solid var(--popover-color);
}

// Bottom
.cc-popover-bottom {
  top: calc(var(--target-height) + 10px);
  left: 50%;
  transform: translateX(-50%);
}

.cc-popover-bottom::before {
  bottom: calc(100% + 1px);
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid #ddd;
}

.cc-popover-bottom::after {
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 8px solid var(--popover-color);
}

// Left
.cc-popover-left {
  right: calc(var(--target-width) + 10px);
  top: calc(var(--target-height)/2);
  transform: translateY(-50%);
}

.cc-popover-left::before {
  top: 50%;
  left: calc(100% + 1px);
  transform: translateY(-50%);
  border-left: 8px solid #ddd;
}

.cc-popover-left::after {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-left: 8px solid var(--popover-color);
}`;

const componentJs =
``;