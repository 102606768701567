import PropTypes from 'prop-types';
import "./FormRow.scss";


export const FormRow = ({
  children,
}) => {


  return (
    <div className="cc-form-row">
      {children}
    </div>
  )
}

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  column: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  width: PropTypes.string,
}