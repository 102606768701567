import {useEffect, useState} from "react";
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import "./Modal.scss";
import "../Button/Button.scss";
import {ModalCode} from "./ModalCode";

export const Modal = ({
  title,
  hasBtn = true,
  btnText,
  children,
  className,
  size = 'dialog',
  isOpen,
  id,
}) => {

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  },[isOpen]);

  const addedClasses =
    `${className ? ` ${className}` : ''}` +
    `${size ? ` cc-modal-${size}` : ''}`;

  const modalId = id || 'modal-' + Math.floor(Math.random() * 9999999);

  const modalComponent = open && (
    ReactDOM.createPortal(
      <div className={`cc-modal-container${addedClasses}`} id={modalId}>

        <button
          className="cc-modal-overlay"
          onClick={() => setOpen(!open)}
          aria-label="Close Modal"
        />
        <div className="cc-modal">

          {size !== 'dialog' 
            ? <div className="cc-modal-header">
              <h3 className="cc-modal-title">{title}</h3>
              <button
                className="cc-modal-close"
                onClick={() => setOpen(!open)}
                aria-label="Close Modal"
              />
            </div>
            : <button className="cc-modal-fixed-close" onClick={() => setOpen(!open)}></button>
          }
          
          <div className="cc-modal-body">
            {children}
          </div>

        </div>

      </div>,
      document.body
    )
  );

  return (<>
    {hasBtn &&
      <button
        className="cc-open-modal cc-button shrink"
        onClick={() => setOpen(!open)}
        aria-haspopup="dialog"
        aria-controls={modalId}
      >
        {btnText}
      </button>
    }
    {modalComponent}
  </>
)};

Modal.propTypes = {
  title: PropTypes.string,
  hasBtn: PropTypes.bool,
  btnText: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOf(['dialog', 'small', 'medium', 'large', 'full']),
  isOpen: PropTypes.bool,
  id: PropTypes.string,
}

export const Modals = () => {

  const ModalContent = () => <><p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
  <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p></>

  return (<>
    <div className="component-area">
      <h2>Modal</h2>
      <p className="description">Use the Modal component to implement pop-up dialogues, modals, or full screen overlays seamlessly on your webpage. Modals enhance user engagement by providing a convenient way to present additional information or actions. This component also includes accessibility tags for screen readers and keyboard navigation functionality.<br /><br />Props include <i>title</i>, <i>hasBtn</i>, <i>btnText</i>, <i>children</i>, <i>className</i>, <i>size</i>, and <i>isOpen</i>.</p>
      <div className="flex-row flex-wrap gap-32">
        <Modal btnText="Default (Medium)" title="My Modal" size="medium">
          <ModalContent />
        </Modal>
        <Modal btnText="Dialog" title="My Modal" size="dialog">
          <p>You must first do such and such to submit the form.</p>
        </Modal>
        <Modal btnText="Small" title="My Modal" size="small">
          <ModalContent />
        </Modal>
        <Modal btnText="Large" title="My Modal" size="large">
          <ModalContent />
        </Modal>
        <Modal btnText="Fullscreen" title="My Modal" size="full">
          <ModalContent />
        </Modal>
      </div>
    </div>
    <ModalCode />
  </>
)};
