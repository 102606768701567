import {useState} from 'react';
import {CodeArea} from '../../components/CodeArea';
import {Toggle} from '../Toggle/Toggle';

export const TableCode = () => {

  const [isReact, setIsReact] = useState(true);

  const handleToggle = (on) => {
    setIsReact(!on);
  }

  return (
    <>
      <div className="react-vs-vanilla">
        <h3>React</h3>
        <Toggle handleToggle={handleToggle} />
        <h3>Vanilla</h3>
      </div>
      
      <div className="code-areas flex-row gap-16">
        {isReact
          ? <>
            <CodeArea title="React" code={componentReact} />
            <CodeArea title="CSS" code={componentCss} />
          </>
          : <>
            <CodeArea title="HTML" code={componentHtml} />
            <CodeArea title="CSS" code={componentCss} />
            <CodeArea title="JS" code={componentJs} />
          </>        
        }
      </div>
    </>
  )
}

const componentReact =
`import {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

/** This is a simple example of how the component works **/
export const Example = () => {

  return (
    <Table
      data={tableData}
      maxHeight="300px"
    />
  )
}
/** This is a simple example of how the component works **/


export const Table = ({
  width,
  maxHeight,
  data,
  className,
}) => {

  const tableRef = useRef();

  useEffect(() => {
    const thCount = tableRef.current.querySelectorAll('th').length;
    const gridStyle = \`repeat(\${thCount}, minmax(150px, 2fr))\`;
    tableRef.current.style.gridTemplateColumns = gridStyle;
  },[]);

  const hasRowLabels = data.cols[0] === '';
  const classNames = 
    \`\${hasRowLabels ? ' cc-table-row-labels' : ''}\` +
    \`\${className ? \` \${className}\` : ''}\`;

  return (
    <table
      style={{width: width, maxHeight: maxHeight}}
      ref={tableRef}
      className={\`cc-table\${classNames}\`}
    >
      <thead>
        <tr>
          {data.cols.map(col => 
            <th>{col}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.rows.map(row =>
          <tr>
            {row.map(col =>
              <td>{col}</td>
            )}
          </tr>
        )}
      </tbody>
    </table>
  )
};

Table.propTypes = {
  width: PropTypes.string,
  maxHeight: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    cols: PropTypes.arrayOf(PropTypes.string).isRequired,
    rows: PropTypes.arrayOf(
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    ).isRequired,
  })
}

// Sample Data
export const tableData = {
  cols: [
    '',
    'Walter White',
    'Jesse Pinkman',
    'Saul Goodman',
  ],
  rows: [
    [
      'Traits',
      'Intelligent',
      'Resourceful',
      'Slick',
    ],
    [
      'Personality',
      'Manipulative',
      'Emotional',
      'Charming',
    ],
  ],
}`;

const componentHtml =
``;

const componentCss =
`// Table
.cc-table {
  display: grid;
  border-collapse: collapse;
  max-width: 100%;
  overflow: auto;
  color: #000;
  border-radius: 4px;
}

.cc-table thead,
.cc-table tbody,
.cc-table tr {
  display: contents;
}

.cc-table th,
.cc-table td {
  padding: 16px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cc-table th {
  position: sticky;
  top: 0;
  background: #EFEFEF;
  text-align: left;
  font-weight: normal;
  font-size: 1.1rem;
}

.cc-table th:last-child {
  border: 0;
}

.cc-table td {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #808080;
  background: #FFF;
  font-weight: 300;
  border-right: 1px solid #EFEFEF;
}

.cc-table td:last-child {
  border: none;
}

.cc-table td:first-child {
  font-weight: 500;
  color: #000;
}

.cc-table tr:nth-child(even) td {
  background: #F9F9F9;
}`;

const componentJs =
``;