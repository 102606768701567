import {useEffect, useRef, useState} from "react";
import "./BoxModel.scss";
import {Button} from "../../public-components/Button/Button";

export const BoxModelPage = () => {

  const [totalExtra, setTotalExtra] = useState(24);
  const [margin, setMargin] = useState(10);
  const [border, setBorder] = useState(10);
  const [padding, setPadding] = useState(10);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(170);
  const [boxDimensions, setBoxDimensions] = useState('');
  const [borderBox, setBorderBox] = useState(true);

  const playgroundRef = useRef(null);

  const fill = {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    position: 'absolute',
  }

  const marginStyles = {
    border: margin + 'px solid red',
    width: borderBox ? (width + (margin * 2)) + 'px' : (width + (margin * 2) + totalExtra) + 'px',
    height: borderBox ? (height + (margin * 2)) + 'px' : (height + (margin * 2) + totalExtra) + 'px',
  }
  console.log(totalExtra*2,margin*2)

  const borderStyles = {
    ...fill,
    border: border + 'px solid blue',
  }

  const paddingStyles = {
    ...fill,
    border: padding + 'px solid green',
  }

  const contentStyles = {
    ...fill,
  }

  useEffect(() => {

    const extra = parseInt(padding * 2) + parseInt(border * 2)

    setTotalExtra(extra);    
    setBoxDimensions(() => {
      if(borderBox) {
        return (width - extra) + 'px X ' + (height - extra) + 'px';
      } else {
        return width + 'px X ' + height + 'px';
      }
    })

  },[margin, border, padding, width, height, borderBox]);

  return(<div className="css-page box-model-page">
    <h2>Box Model</h2>

    <p className="description">
      The box model is one of the foundational concepts of css. This interactive diagram shows you how properties are distributed in an element. <ul>
        <li><span className="red">Margin</span> is the space around the outside of an element.</li>
        <li><span className="blue">Border</span> is the outermost part of an element used to visually define the outside of the element.</li>
        <li><span className="green">Padding</span> is the space around the content on the inside of an element.</li>
      </ul>
      <b>Box sizing</b> is a css property that defines how the size of an element is calculated. The value <b>content-box</b> is the default value and it means that when you set the width or height of an element, that is going to be the width or height of the <span className="purps">element content</span> and padding and border will be added to that.<br />The value <b>border-box</b> means that when you set the width or height of an element, that is going to be the width or height of the element's <span className="blue">border</span> so the actual content will be the width - <span className="blue">border width x 2</span> - <span className="green">padding width x 2</span>. x 2 because we have to account for both left and right. And then <span className="red">margin</span> is always on the exterior of an element so it will not affect the width or height in this context. <b>Border-box</b> is generally preferrable when using border or padding so you can control the actual width of the visual element since the border is the outside visually.
    </p>

    <div className="playground no-background flex-row">

      <div data-playground-margin={'margin: ' + margin + 'px'} className="playground-margin transform-center" style={marginStyles}>
        <div data-playground-border={'border: ' + border + 'px'} className="playground-border" style={borderStyles}>
          <div data-playground-padding={'padding: ' + padding + 'px'} className="playground-padding" style={paddingStyles}>
            <div ref={playgroundRef} data-playground-content="element content" className="playground-content flex flex-center" style={contentStyles}>
              <div className="playground-text"><span>{boxDimensions}</span></div>
              <div className="box-sizing-desc">
                <p>box-sizing: <b>{borderBox ? 'border-box' : 'content-box'};</b></p>
                <hr />
                <p>
                  <span className="purps">width: </span>
                  {borderBox
                    ? <span>{width - (padding * 2) - (border * 2)}px;</span>
                    : <span>{width}px;</span>
                  }
                  &nbsp;&nbsp;
                  <span className="purps">height: </span>
                  {borderBox
                    ? <span>{height - (padding * 2) - (border * 2)}px;</span>
                    : <span>{height}px;</span>
                  }
                </p>
                <p>
                  <span className="green">width: </span>
                  {borderBox
                    ? <span>{width - (border * 2)}px;</span>
                    : <span>{width + (padding * 2)}px;</span>
                  }
                  &nbsp;&nbsp;
                  <span className="green">height: </span>
                  {borderBox
                    ? <span>{height - (border * 2)}px;</span>
                    : <span>{height + (padding * 2)}px;</span>
                  }
                </p>
                <p>
                  <span className="blue">width: </span>
                  {borderBox
                    ? <><span>{width}</span>px;</>
                    : <span>{width + (padding * 2) + (border * 2)}px;</span>
                  }
                  &nbsp;&nbsp;
                  <span className="blue">height: </span>
                  {borderBox
                    ? <><span>{height}</span>px;</>
                    : <span>{height + (padding * 2) + (border * 2)}px;</span>
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    {/* <div className="content-scroll"> */}
      <div className="playground-options flex-column">
        <div className="flex-row gap-16">
          <label style={{color: 'black'}}>box-sizing:</label>
          <Button className={borderBox ? 'active' : ''} onClick={() => setBorderBox(true)}>border-box</Button>
          <Button className={!borderBox ? 'active' : ''} onClick={() => setBorderBox(false)}>content-box</Button>
        </div>
        <div className="flex-row gap-16">
          <label style={{color: 'red'}}>margin:</label>
          <Button className={margin === 0 ? 'active' : ''} onClick={() => setMargin(0)}>none</Button>
          <Button className={margin === 5 ? 'active' : ''} onClick={() => setMargin(5)}>5px</Button>
          <Button className={margin === 10 ? 'active' : ''} onClick={() => setMargin(10)}>10px</Button>
          <Button className={margin === 15 ? 'active' : ''} onClick={() => setMargin(15)}>15px</Button>
        </div>
        <div className="flex-row gap-16">
          <label style={{color: 'blue'}}>border:</label>
          <Button className={border === 0 ? 'active' : ''} onClick={() => setBorder(0)}>none</Button>
          <Button className={border === 5 ? 'active' : ''} onClick={() => setBorder(5)}>5px</Button>
          <Button className={border === 10 ? 'active' : ''} onClick={() => setBorder(10)}>10px</Button>
          <Button className={border === 15 ? 'active' : ''} onClick={() => setBorder(15)}>15px</Button>
        </div>
        <div className="flex-row gap-16">
          <label style={{color: 'green'}}>padding:</label>
          <Button className={padding === 0 ? 'active' : ''} onClick={() => setPadding(0)}>none</Button>
          <Button className={padding === 5 ? 'active' : ''} onClick={() => setPadding(5)}>5px</Button>
          <Button className={padding === 10 ? 'active' : ''} onClick={() => setPadding(10)}>10px</Button>
          <Button className={padding === 15 ? 'active' : ''} onClick={() => setPadding(15)}>15px</Button>
        </div>
        <div className="flex-row gap-16">
          <label style={{color: 'black'}}>width:</label>
          <Button className={width === 200 ? 'active' : ''} onClick={() => setWidth(200)}>200px</Button>
          <Button className={width === 300 ? 'active' : ''} onClick={() => setWidth(300)}>300px</Button>
          <Button className={width === 400 ? 'active' : ''} onClick={() => setWidth(400)}>400px</Button>
          <Button className={width === 600 ? 'active' : ''} onClick={() => setWidth(600)}>600px</Button>
        </div>
        <div className="flex-row gap-16 m-bottom-64">
          <label style={{color: 'black'}}>height:</label>
          <Button className={height === 140 ? 'active' : ''} onClick={() => setHeight(140)}>140px</Button>
          <Button className={height === 170 ? 'active' : ''} onClick={() => setHeight(170)}>170px</Button>
          <Button className={height === 200 ? 'active' : ''} onClick={() => setHeight(200)}>200px</Button>
        </div>
      </div>
    {/* </div> */}
  </div>
)}