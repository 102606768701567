
import './Test.scss'

/** This is a simple example of how the component works **/
export const Example = ({}) => {

  return (
    <div />
  )
}
/** This is a simple example of how the component works **/

